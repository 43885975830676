import { createAction } from '@reduxjs/toolkit';

import dashboardSlice from './slice';
import {
  FetchRecordTypesActionPayload,
  FetchTypesStatisticsActionPayload,
  GetCategoriesActionPayload,
} from '@bus/dashboard/typedefs';

export const dashboardActions = {
  ...dashboardSlice.actions,
  fetchCategories: createAction(
    'dashboard/fetchCategories',
    (payload: GetCategoriesActionPayload) => {
      return { payload };
    },
  ),
  fetchRecordTypes: createAction(
    'dashboard/fetchRecordTypes',
    (payload: FetchRecordTypesActionPayload) => {
      return { payload };
    },
  ),
  fetchTypesStatistics: createAction(
    'dashboard/fetchTypesStatistics',
    (payload: FetchTypesStatisticsActionPayload) => {
      return { payload };
    },
  ),
  getTypes: createAction('dashboard/fetchTypes'),
  // INJECT
};
