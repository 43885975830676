import React, { FC } from 'react';

import { Box, Button } from '@mui/material';

import { styles } from './styles';
import { Link } from 'react-router-dom';

type NavigationMenuItemProps = {
  ariaLabel: string;
  text: string;
  isActive: boolean;
  icon: any;
  to: string;
};

export const NavigationMenuItem: FC<NavigationMenuItemProps> = ({
  ariaLabel,
  isActive,
  text,
  icon,
  to,
}) => {
  return (
    <Button
      component={Link}
      to={to}
      variant={'navButton'}
      aria-label={ariaLabel}
      sx={[
        styles.navigationMenuItem,
        isActive && styles.navigationMenuItemActive,
      ]}>
      {icon}
      {isActive && <Box>{text}</Box>}
    </Button>
  );
};

export default NavigationMenuItem;
