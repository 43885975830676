import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { documentsActions } from '../../actions';
import { endpoints } from '@REST/endpoints';
import {
  FetchDocumentsActionPayload,
  FillDocumentsActionPayload,
} from '@bus/documents/typedefs';
import { PayloadAction } from '@reduxjs/toolkit';

export function* fetchDocuments({
  payload,
}: PayloadAction<FetchDocumentsActionPayload>) {
  try {
    yield effects.put(documentsActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `${endpoints.categories}${payload}/` },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillDocumentsActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(documentsActions.fillDocuments(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(documentsActions.stopFetching());
  }
}
