export const endpoints = {
  profile: 'users/me/',
  categories: 'categories/',
  saveFileInDocuments: 'documents/',
  documents: 'documents/',
  deleteFiles: 'files/delete-multiple/',
  pages: 'pages/',
  signUpMail: 'auth/signup/',
  signUpPassword: ({
    id,
    registration_token,
  }: {
    id: string;
    registration_token: string;
  }) => `auth/signup/${id}/${registration_token}/set-password/`,
  signIn: 'auth/signin/',
  resetPassword: 'auth/reset-password-request/',
  setNewPassword: ({
    user_id,
    reset_token,
  }: {
    user_id: string;
    reset_token: string;
  }) => `auth/reset-password/${user_id}/${reset_token}/`,
  refreshToken: 'auth/token/refresh/',
  userEdit: 'users/edit/',
  userAddressEdit: 'users/edit-address/',
  userPartnerEdit: 'users/personal-network/',
  changeEmailRequest: 'users/change-email-request/',
  changeEmailConfirm: 'users/change-email-confirm/',
  changePhoneRequest: 'users/change-phone-request/',
  changePhoneCodeConfirm: 'users/change-phone/',
  getLanguage: 'languages/',
  sendEmailAgain: 'auth/send-activation-email-again/',
  newPassword: 'users/change-password-request/',
  dashboardCategories: 'dashboard/categories/',
  dashboardRecordTypes: 'dashboard/record-types/',
  dashboardTypes: 'dashboard/types/',
  dashboardTypesStatistics: 'dashboard/types-statistics/',
  netIncomeRent: 'net_income/rent/',
  netIncomeHealthInsurance: 'net_income/health-insurance/',
  netIncomeLivingCosts: 'net_income/living-costs/',
  netIncomeOtherInsurance: 'net_income/other-insurance/',
  netIncome: 'net_income/all/',
  netWorth: 'net_worth/all/',
  removeNetIncomeById: 'net_income/all/',
  removeNetWorthById: 'net_worth/all/',
  netIncomeRentIncome: 'net_income/rental-income/',
  netIncomeSubscriptions: 'net_income/subscriptions/',
  netIncomeTax: 'net_income/taxes/',
  netIncomeSalary: 'net_income/salary/',
  netIncomeDividends: 'net_income/dividends/',
  netIncomeInterests: 'net_income/interests-income/',
  netIncomeOtherIncome: 'net_income/other-income/',
  proposalUsers: 'proposal_users/',
  faq: 'faqs/',
  netWorthMoneyAndBank: 'net_worth/money-and-bank/',
  netWorthInvestmentPortfolio: 'net_worth/investments/',
  netWorthPensionAndProvision: 'net_worth/pension-and-provision/',
  netWorthLiabilities: 'net_worth/liabilities/',
  netWorthRealEstate: 'net_worth/real-estate/',
  netWorthOtherAssets: 'net_worth/other-assets/',
  netWorthInvestments: 'net_worth/investments/',
  enableTwoStepVerification: 'users/two-factor-request/',
  changePhone: 'users/change-phone/',
  verifyCode: 'auth/verify-code/',
  sendSmsCodeAgain: 'auth/send-sms-code-again/',
  checklist: 'categories-check-list/',
  netIncomeOtherExpenses: 'net_income/other-expense/',
  getSettings: 'settings/',
  getTmpToken: 'admin-token/',
};
