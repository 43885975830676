/* eslint-disable prettier/prettier */
import React, { lazy } from 'react';

import layouts from '@layouts/index';
import { book } from '@routes/book';

import { AppRoute } from '@setup/typedefs';
const TermsPrivacyPage = lazy(()=>import('@pages/common/TermsPrivacyPage'));
const TermsPrivacyContentPage = lazy(()=>import('src/pages/common/TermsPrivacyContentPage'));
const CheckYouEmailPage = lazy(()=>import('src/pages/common/CheckYouEmailPage'));
const SetNewPasswordPage = lazy(()=>import('src/pages/common/SetNewPasswordPage'));
const AdminViewPage = lazy(()=>import('@pages/common/AdminViewPage'));

// IMPORTS
export const commonRoutes: AppRoute<any>[] = [
  // inject
    {
      path: `${book.termsPrivacy}`,
      Component: <TermsPrivacyPage />,
      Layout: layouts.CommonLayout,
      layoutProps: {},
    },
    {
      path: `${book.terms}`,
      Component: <TermsPrivacyContentPage />,
      Layout: layouts.CommonLayout,
      layoutProps: {},
    },
    {
      path: `${book.privacy}`,
      Component: <TermsPrivacyContentPage />,
      Layout: layouts.CommonLayout,
      layoutProps: {},
    },
    {
      path: `${book.checkYouEmail}/:registration_token/:email`,
      Component: <CheckYouEmailPage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.setNewPassword}/:user_id/:reset_token`,
      Component: <SetNewPasswordPage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.adminView}`,
      Component: <AdminViewPage />,
      Layout: layouts.CommonLayout,
      layoutProps: {},
    },
    // INJECT
];