import { Components, Theme } from '@mui/material';

export const MuiButton: Partial<Components<Theme>> = {
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      color: 'primary',
    },
    styleOverrides: {
      root: {
        fontFamily: 'Poppins, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        textTransform: 'unset',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '24px',
        border: 'unset',
        margin: 0,
        minWidth: 'unset',
        minHeight: 'unset',
        height: '56px',
        backgroundColor: '#1F3557',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#E6D4B8',
        },
        '&:focus': {
          backgroundColor: '#E6D4B8',
        },
        '& .MuiButton-startIcon': {
          margin: 0,
        },
        '&.Mui-disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          color: 'rgba(0, 0, 0, 0.38)',
        },
      },
    },
    variants: [
      {
        props: { variant: 'navButton' },
        style: {
          fontSize: '12px',
          background: '#E6D4B8',
          color: '#1F3557',
          gap: '6px',
          '&:hover': {
            backgroundColor: '#E6D4B8',
          },
          '&:focus': {
            backgroundColor: '#FCFAF6',
          },
        },
      },
      {
        props: { variant: 'roundedButtonWithIcon' },
        style: {
          fontSize: '12px',
          height: '48px',
          width: '48px',
          backgroundColor: '#E6D4B8',
          color: '#1F3557',
          '&:hover': {
            backgroundColor: '#E6D4B8',
          },
          '&:focus': {
            backgroundColor: '#E6D4B8',
          },
        },
      },
      {
        props: { variant: 'buttonWithIconOnly' },
        style: {
          fontSize: '12px',
          height: '48px',
          width: '48px',
          backgroundColor: 'unset',
          color: '#1F3557',
          '&:hover': {
            backgroundColor: '#E6D4B8',
          },
          '&:focus': {
            backgroundColor: '#E6D4B8',
          },
        },
      },
      {
        props: { variant: 'buttonJustIcon' },
        style: {
          height: 'auto',
          width: 'auto',
          backgroundColor: 'unset',
          padding: 0,
          margin: 0,
          '&:hover': {
            backgroundColor: 'unset',
          },
          '&:focus': {
            backgroundColor: 'unset',
          },
        },
      },
      {
        props: { variant: 'mainButton' },
        style: {
          backgroundColor: '#1F3557',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#E6D4B8',
          },
          '&:focus': {
            backgroundColor: '#E6D4B8',
          },
          '& .MuiButton-startIcon': {
            marginRight: '6px',
          },
        },
      },
      {
        props: { variant: 'textButton' },
        style: {
          backgroundColor: 'unset',
          color: '#1F3557',
          '&:hover': {
            backgroundColor: 'unset',
          },
          '&:focus': {
            backgroundColor: 'unset',
          },
          '&.Mui-disabled': {
            opacity: 0.5,
          },
          '& .MuiButton-startIcon': {
            marginRight: '8px',
          },
        },
      },
      {
        props: { variant: 'editProfileImage' },
        style: {
          backgroundColor: '#FCFAF6',
          boxShadow:
            '0px 19px 7px rgba(176, 152, 130, 0.01), 0px 10px 6px rgba(176, 152, 130, 0.05), 0px 5px 5px rgba(176, 152, 130, 0.09), 0px 1px 3px rgba(176, 152, 130, 0.1), 0px 0px 0px rgba(176, 152, 130, 0.1)',
          color: '#1F3557',
          height: '48px',
          '& .MuiButton-startIcon': {
            marginRight: '8px',
          },
        },
      },
      {
        props: { variant: 'secondaryButton' },
        style: {
          backgroundColor: '#E6D4B8',
          color: 'rgba(0,0,0,0.87)',
          '&:hover': {
            backgroundColor: '#1F3557',
            color: '#ffffff',
          },
          '&:focus': {
            backgroundColor: '#1F3557',
            color: '#ffffff',
          },
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    ],
  },
};
