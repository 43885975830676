import { createAction } from '@reduxjs/toolkit';

import documentsSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const documentsActions = {
  ...documentsSlice.actions,
  fetchCategories: createAction('documents/fetchCategories'),
  fetchSingleCategory: createAction(
    'documents/fetchSingleCategory',
    (payload) => {
      return {
        payload,
      };
    },
  ),
  fetchDocuments: createAction('documents/fetchDocuments', (payload) => {
    return {
      payload,
    };
  }),
  saveFiles: createAction(
    'documents/saveFiles',
    prepareActions.movePromiseToMeta,
  ),
  updateFiles: createAction(
    'documents/updateFiles',
    prepareActions.movePromiseToMeta,
  ),
  deleteFiles: createAction(
    'documents/deleteFiles',
    prepareActions.movePromiseToMeta,
  ),
  fetchChecklist: createAction('documents/fetchChecklist'),
  // INJECT
};
