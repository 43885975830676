import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { financialRecordsActions } from '../../actions';
import { Record, NetIncomeActionPayload } from '../../typedefs';
import { endpoints } from '@REST/endpoints';
import { Pagination } from '@setup/typedefs';

export function* netIncome({ payload }: PayloadAction<NetIncomeActionPayload>) {
  const { category } = payload;
  try {
    yield effects.put(financialRecordsActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `${endpoints.netIncome}?category=${category}` },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: Pagination<Record[]> = yield effects.call([response, 'json']);
    yield effects.put(financialRecordsActions.fillNetIncome(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(financialRecordsActions.stopFetching());
  }
}
