export const styles = {
  greetingUser: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '26px',
  },
  userPhotoWrapper: {
    width: '48px',
    height: '48px',
    borderRadius: '16px',
    background: '#D1DFC3',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  userPhoto: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};
