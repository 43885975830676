export const privateBook = {
  dashboard: '/dashboard',
  todo: '/todo',
  support: '/support',
  documents: '/documents',
  documentsSingleCategory: '/documents-single-category',
  preview: '/documents/preview',
  previewSample: '/documents/preview-sample',
  previewFilledDocument: '/documents/preview-filled-document',
  questionsName: '/questions/name',
  questionsBirthday: '/questions/birthday',
  questionsCivilStatus: '/questions/civil-status',
  questionsPartnersBirthday: '/questions/partners-birthday',
  questionsPartnerName: '/questions/partner-name',
  questionsDependableChildren: '/questions/dependable-children',
  questionsAddress: '/questions/address',
  profile: '/profile',
  editPersonalInformation: '/edit/personal-information',
  editBirthday: '/edit/birthday',
  editResidentialAddress: '/edit/residential-address',
  editCivilStatus: '/edit/civil-status',
  editPartner: '/edit/partner',
  editDependableChildren: '/edit/dependable-children',
  editMail: '/edit/mail',
  editPhone: '/edit/phone',
  emailChange: '/email-change',
  phoneCode: '/phone-code',
  twoStepVerification: '/two-step-verification',
  codeForTwoStepVerification: '/two-step-verification-code',
  financialRecordsList: '/financial-records-list',
  addRent: '/add-rent',
  addRentIncome: '/add-rent-income',
  addHealthInsurance: '/add-health-insurance',
  addOtherInsurance: '/add-other-insurance',
  addLivingCosts: '/add-living-costs',
  editRent: '/edit-rent',
  editHealthInsurance: '/edit-health-insurance',
  editOtherInsurance: '/edit-other-insurance',
  editRentIncome: '/edit-rent-income',
  editLivingCosts: '/edit-living-costs',
  addSubscriptions: '/add-subscriptions',
  editSubscriptions: '/edit-subscriptions',
  addTax: '/add-tax',
  editTax: '/edit-tax',
  addSalary: '/add-salary',
  editSalary: '/edit-salary',
  addDividends: '/add-dividends',
  editDividends: '/edit-dividends',
  addInterests: '/add-interests',
  editInterests: '/edit-interests',
  addOtherIncome: '/add-other-income',
  editOtherIncome: '/edit-other-income',
  documentPreview: '/document-preview',
  acceptProposalDone: '/accept-proposal-done',
  addMoneyAndBank: '/add-money-and-bank',
  editMoneyAndBank: '/edit-money-and-bank',
  addInvestmentPortfolio: '/add-investment-portfolio',
  editInvestmentPortfolio: '/edit-investment-portfolio',
  addPensionAndProvision: '/add-pension-and-provision',
  editPensionAndProvision: '/edit-pension-and-provision',
  addLiabilities: '/add-liabilities',
  editLiabilities: '/edit-liabilities',
  addRealEstate: '/add-real-estate',
  editRealEstate: '/edit-real-estate',
  addOtherAssets: '/add-other-assets',
  editOtherAssets: '/edit-other-assets',
  documentsPre: '/documents-pre',
  checklist: '/checklist',
  previewSampleChecklist: '/preview-sample-checklist',
  addOtherExpenses: '/add-other-expenses',
  editOtherExpenses: '/edit-other-expenses',
  subscribe: '/subscribe',
  addDistribution: '/add-distribution',
  editDistribution: '/edit-distribution',
  addHousing: '/add-housing',
  editHousing: '/edit-housing',
  addFood: '/add-food',
  editFood: '/edit-food',
  // INJECT
};
