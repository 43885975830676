import { Components, Theme } from '@mui/material';

export const MuiAccordion: Partial<Components<Theme>> = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        background: '#FCFAF6',
        '&.MuiPaper-root': {
          borderRadius: '18px',
        },
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded:first-of-type': {
          margin: 0,
        },
        '& .MuiAccordionSummary-root': {
          height: '100px',
          padding: '0 24px',
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          width: '48px',
          height: '48px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
        },
      },
    },
  },
};
