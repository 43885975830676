import { Components, Theme } from '@mui/material';

export const MuiInputLabel: Partial<Components<Theme>> = {
  MuiInputLabel: {
    styleOverrides: {
      root: () => {
        return {
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 400,
          fontSize: 12,
          transform: 'none',
          left: '16px',
          top: '12px',
          color: '#1F3557',
          '&.Mui-focused': {
            transform: 'none',
            fontSize: 12,
          },
        };
      },
      outlined: {
        '&.MuiFormLabel-filled': {
          transform: 'none',
          fontSize: 12,
        },
      },
    },
  },
};
