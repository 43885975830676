import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { useTranslation } from 'react-i18next';

type DoneProps = {
  title: string;
  text: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
};

export const Done: React.FC<ModalTypes.ModalComponentProps<DoneProps>> = ({
  closeFn,
  onConfirm,
  title,
  text,
}) => {
  const { t } = useTranslation();
  const closeModal = () => {
    onConfirm();
    closeFn();
  };

  return (
    <Box sx={styles.done}>
      {title && (
        <Box>
          <Typography variant={'h5'}>{title}</Typography>
        </Box>
      )}
      {text && (
        <Box>
          <Typography variant={'body2'}>{text}</Typography>
        </Box>
      )}
      <Box display={'flex'} gap={'10px'} mt={'24px'}>
        <Button
          onClick={closeModal}
          color={'primary'}
          variant={'mainButton'}
          sx={{ width: '124px' }}>
          {t('Ok')}
        </Button>
      </Box>
    </Box>
  );
};

export default Done;
