export const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    alignItems: 'center',
    maxWidth: '320px',
    width: '100%',
    gap: '24px',
    textAlign: 'center',
  },
};
