import { v4 as uuidv4 } from 'uuid';

export const convertFileForView = async (files: any) => {
  const promises = [];

  if (files.length) {
    for (const file of files) {
      const reader = new FileReader();

      promises.push(
        new Promise((resolve) => {
          reader.onload = () => {
            resolve({
              file,
              mime_type: file.mime_type || file.type,
              urlToFront:
                file.mime_type === 'application/pdf'
                  ? URL.createObjectURL(file)
                  : reader.result,
              name: file.name,
              id: `tmp_id_${uuidv4()}`,
            });
          };

          reader.readAsDataURL(file);
        }),
      );
    }

    return await Promise.all(promises).then((photoUrls) => {
      return photoUrls.map((item: any) => item);
    });
  }

  return null;
};

export const toUnderscoreCase = (str: string) => {
  return str
    .replace(/([a-z\d])([A-Z])/g, '$1_$2')
    .replace(/[-\s]/g, '_')
    .toLowerCase();
};

export const isMobileDevice = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

export function capitalizeFirstLetter(str: string) {
  return str.replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
}

export const getTwoDigits = (digit: number) => {
  return digit.toString().length === 2 ? digit : '0' + digit;
};

export const formatValues = (val: string | number | undefined): string => {
  const newVal = Number(Number(val || 0).toFixed(0));

  return newVal
    .toLocaleString('de-DE', {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\./g, "'");
};
