import { PaletteOptions } from '@mui/material/styles/createPalette';
export const light: PaletteOptions = {
  mode: 'light',
  common: {
    white: 'rgb(255, 255, 255)',
    red: '#FF6565',
    green: '#65FF74',
    black: 'rgb(0, 0, 0)',
  },
  primary: {
    light: 'rgb(69, 88, 118)',
    contrastText: 'rgb(255, 255, 255)',
    dark: 'rgb(19, 32, 52)',
    main: 'rgb(31, 53, 87)',
  },
  background: {
    paper: 'rgb(252, 250, 246)',
    default: 'rgb(248, 243, 238)',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.8700000047683716)',
    secondary: 'rgba(0, 0, 0, 0.6000000238418579)',
    disabled: 'rgba(0, 0, 0, 0.3799999952316284)',
  },
  error: {
    main: 'rgb(211, 47, 47)',
    light: 'rgb(219, 88, 88)',
    dark: 'rgb(147, 32, 32)',
    contrastText: 'rgb(255, 255, 255)',
  },
  secondary: {
    dark: 'rgb(213, 183, 136)',
    main: 'rgb(230, 212, 184)',
    light: 'rgb(238, 226, 207)',
    contrastText: 'rgba(0, 0, 0, 0.8700000047683716)',
  },
  divider: 'rgba(0, 0, 0, 0.11999999731779099)',
  info: {
    main: 'rgb(135, 178, 227)',
    light: 'rgb(200, 220, 239)',
    dark: 'rgb(49, 97, 144)',
    contrastText: 'rgb(255, 255, 255)',
  },
  warning: {
    main: 'rgb(255, 161, 83)',
    light: 'rgb(255, 178, 115)',
    dark: 'rgb(165, 75, 1)',
    contrastText: 'rgb(255, 255, 255)',
  },
  success: {
    main: 'rgb(159, 168, 48)',
    light: 'rgb(193, 199, 113)',
    dark: 'rgb(94, 99, 29)',
    contrastText: 'rgb(255, 255, 255)',
  },
  grey: {
    '50': 'rgb(250, 250, 250)',
    '100': 'rgb(245, 245, 245)',
    '200': 'rgb(238, 238, 238)',
    '300': 'rgb(224, 224, 224)',
    '400': 'rgb(189, 189, 189)',
    '500': 'rgb(158, 158, 158)',
    '600': 'rgb(117, 117, 117)',
    '700': 'rgb(97, 97, 97)',
    '800': 'rgb(66, 66, 66)',
    '900': 'rgb(33, 33, 33)',
    A700: 'rgb(97, 97, 97)',
    A400: 'rgb(189, 189, 189)',
    A200: 'rgb(238, 238, 238)',
    A100: 'rgb(245, 245, 245)',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.5400000214576721)',
    hover: 'rgba(0, 0, 0, 0.07999999821186066)',
    selected: 'rgba(0, 0, 0, 0.07999999821186066)',
    disabled: 'rgba(0, 0, 0, 0.25999999046325684)',
    disabledBackground: 'rgba(0, 0, 0, 0.07999999821186066)',
    focus: 'rgba(0, 0, 0, 0.11999999731779099)',
  },
};
// types
declare module '@mui/material/styles/createPalette' {
  export interface CommonColors {
    white: string;
    red: string;
    green: string;
    black: string;
  }
  export interface PaletteOptions {
    common?: Partial<CommonColors>;
  }
}
