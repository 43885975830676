import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { documentsActions } from '../../actions';
import { UpdateFilesActionPayload } from '../../typedefs';
import { endpoints } from '@REST/endpoints';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* updateFiles({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<UpdateFilesActionPayload>) {
  try {
    const { id, formData } = payload;
    yield effects.put(documentsActions.startFetching());
    const response: Response = yield effects.apply(api, api.put, [
      { endpoint: `${endpoints.saveFileInDocuments}${id}/`, body: formData },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(documentsActions.stopFetching());
  }
}
