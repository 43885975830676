import React from 'react';

import { ModalTypes } from '@core/Modal/types';

import { ConfirmOrCancel } from './ConfirmOrCancel';

import { Done } from './Done';

// IMPORTS
export const registeredModals: ModalTypes.RegisteredModals = {
  ConfirmOrCancel,
  Done,
  // INJECT
};
