// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  FinancialRecordsState,
  FillNetIncomeActionPayload,
  FillNetRecordActionPayload,
  FillNetWorthActionPayload,
} from './typedefs';

import { emptyPaginationState } from '@setup/typedefs';

const initialState: FinancialRecordsState = {
  isFetching: false,
  netIncomeList: emptyPaginationState,
  netWorthList: emptyPaginationState,
  record: null,
};

const financialRecordsSlice = createSlice({
  name: 'financialRecords',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillNetIncome(state, action: PayloadAction<FillNetIncomeActionPayload>) {
      state.netIncomeList = action.payload;
    },
    fillNetRecord(state, action: PayloadAction<FillNetRecordActionPayload>) {
      state.record = action.payload;
    },
    fillNetWorth(state, action: PayloadAction<FillNetWorthActionPayload>) {
      state.netWorthList = action.payload;
    },
    clearRecord(state) {
      state.record = null;
    },
    // INJECT
  },
});

export default financialRecordsSlice;
