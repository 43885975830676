import { OnFormSubmitPayload } from '@core/FinalForm/typedefs';

const prepareActions = {
  movePromiseToMeta: ({ values, resolve, reject }: OnFormSubmitPayload) => {
    return {
      payload: values,
      meta: {
        resolve,
        reject,
      },
    };
  },
  withNavigate: (navigate?: any) => {
    return { payload: navigate };
  },
};

export default prepareActions;
