import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { dashboardActions } from '../../actions';
import { endpoints } from '@REST/endpoints';
import {
  FetchTypesStatisticsActionPayload,
  FillTypesStatisticsActionPayload,
} from '@bus/dashboard/typedefs';
import { PayloadAction } from '@reduxjs/toolkit';

export function* fetchTypesStatistics({
  payload,
}: PayloadAction<FetchTypesStatisticsActionPayload>) {
  const { month } = payload;
  try {
    yield effects.put(dashboardActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `${endpoints.dashboardTypesStatistics}?months=${month}` },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillTypesStatisticsActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(dashboardActions.fillTypesStatistics(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(dashboardActions.stopFetching());
  }
}
