import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { documentsActions } from '../../actions';
import { DeleteFilesActionPayload } from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { endpoints } from '@REST/endpoints';

export function* deleteFiles({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<DeleteFilesActionPayload>) {
  const { idsToDelete, documentId } = payload;
  try {
    yield effects.put(documentsActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      {
        endpoint: `${
          endpoints.deleteFiles
        }?document=${documentId}&files=${idsToDelete.join(',')}`,
      },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(documentsActions.stopFetching());
  }
}
