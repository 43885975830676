import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { dashboardActions } from '../../actions';
import { endpoints } from '@REST/endpoints';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  FetchRecordTypesActionPayload,
  FillRecordTypesActionPayload,
} from '@bus/dashboard/typedefs';
import { Pagination } from '@setup/typedefs';

export function* fetchRecordTypes({
  payload,
}: PayloadAction<FetchRecordTypesActionPayload>) {
  const { category } = payload;
  try {
    yield effects.put(dashboardActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      {
        endpoint: `${endpoints.dashboardRecordTypes}?category=${category}`,
      },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: Pagination<FillRecordTypesActionPayload[]> = yield effects.call(
      [response, 'json'],
    );
    yield effects.put(dashboardActions.fillRecordTypes(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(dashboardActions.stopFetching());
  }
}
