import * as effects from 'redux-saga/effects';

import { financialRecordsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  addRent,
  addHealthInsurance,
  netIncome,
  fetchNetIncomeRecordById,
  addOtherInsurance,
  editRent,
  editHealthInsurance,
  addRentIncome,
  editRentIncome,
  editOtherInsurance,
  addLivingCosts,
  editLivingCosts,
  addSubscriptions,
  editSubscriptions,
  addTax,
  editTax,
  addSalary,
  fetchSalary,
  editSalary,
  addDividends,
  editDividends,
  addInterests,
  editInterests,
  addOtherIncome,
  editOtherIncome,
  addMoneyAndBank,
  netWorth,
  editMoneyAndBank,
  fetchNetWorthRecordById,
  deleteNetRecordById,
  addInvestmentPortfolio,
  editInvestmentPortfolio,
  addPensionAndProvision,
  editPensionAndProvision,
  addLiabilities,
  editLiabilities,
  fetchNetWorthLiabilitiesById,
  addRealEstate,
  editRealEstate,
  fetchNetWorthRealEstateById,
  addOtherAssets,
  editOtherAssets,
  addOtherExpenses,
  editOtherExpenses,
  addFood,
  editFood,
  addHousing,
  editHousing,
  addDistribution,
  editDistribution,
  fetchInvestmentPortfolioById,
} from './workers';

// IMPORTS
function* watchAddRent() {
  yield effects.takeEvery(financialRecordsActions.addRent.type, addRent);
}
function* watchAddHealthInsurance() {
  yield effects.takeEvery(
    financialRecordsActions.addHealthInsurance.type,
    addHealthInsurance,
  );
}
function* watchNetIncome() {
  yield effects.takeEvery(
    financialRecordsActions.fetchNetIncome.type,
    netIncome,
  );
}
function* watchDeleteNetRecordById() {
  yield effects.takeEvery(
    financialRecordsActions.deleteNetRecordById.type,
    deleteNetRecordById,
  );
}
function* watchFetchNetIncomeRecordById() {
  yield effects.takeEvery(
    financialRecordsActions.fetchNetIncomeRecordById.type,
    fetchNetIncomeRecordById,
  );
}
function* watchAddOtherInsurance() {
  yield effects.takeEvery(
    financialRecordsActions.addOtherInsurance.type,
    addOtherInsurance,
  );
}
function* watchEditRent() {
  yield effects.takeEvery(financialRecordsActions.editRent.type, editRent);
}
function* watchEditHealthInsurance() {
  yield effects.takeEvery(
    financialRecordsActions.editHealthInsurance.type,
    editHealthInsurance,
  );
}
function* watchAddRentIncome() {
  yield effects.takeEvery(
    financialRecordsActions.addRentIncome.type,
    addRentIncome,
  );
}
function* watchEditRentIncome() {
  yield effects.takeEvery(
    financialRecordsActions.editRentIncome.type,
    editRentIncome,
  );
}
function* watchEditOtherInsurance() {
  yield effects.takeEvery(
    financialRecordsActions.editOtherInsurance.type,
    editOtherInsurance,
  );
}
function* watchAddLivingCosts() {
  yield effects.takeEvery(
    financialRecordsActions.addLivingCosts.type,
    addLivingCosts,
  );
}
function* watchEditLivingCosts() {
  yield effects.takeEvery(
    financialRecordsActions.editLivingCosts.type,
    editLivingCosts,
  );
}
function* watchAddSubscriptions() {
  yield effects.takeEvery(
    financialRecordsActions.addSubscriptions.type,
    addSubscriptions,
  );
}
function* watchEditSubscriptions() {
  yield effects.takeEvery(
    financialRecordsActions.editSubscriptions.type,
    editSubscriptions,
  );
}
function* watchAddTax() {
  yield effects.takeEvery(financialRecordsActions.addTax.type, addTax);
}
function* watchEditTax() {
  yield effects.takeEvery(financialRecordsActions.editTax.type, editTax);
}
function* watchAddSalary() {
  yield effects.takeEvery(financialRecordsActions.addSalary.type, addSalary);
}
function* watchFetchSalary() {
  yield effects.takeEvery(
    financialRecordsActions.fetchSalary.type,
    fetchSalary,
  );
}
function* watchFetchInvestment() {
  yield effects.takeEvery(
    financialRecordsActions.fetchInvestmentPortfolioById.type,
    fetchInvestmentPortfolioById,
  );
}
function* watchEditSalary() {
  yield effects.takeEvery(financialRecordsActions.editSalary.type, editSalary);
}
function* watchAddDividends() {
  yield effects.takeEvery(
    financialRecordsActions.addDividends.type,
    addDividends,
  );
}
function* watchEditDividends() {
  yield effects.takeEvery(
    financialRecordsActions.editDividends.type,
    editDividends,
  );
}
function* watchAddInterests() {
  yield effects.takeEvery(
    financialRecordsActions.addInterests.type,
    addInterests,
  );
}
function* watchEditInterests() {
  yield effects.takeEvery(
    financialRecordsActions.editInterests.type,
    editInterests,
  );
}
function* watchAddOtherIncome() {
  yield effects.takeEvery(
    financialRecordsActions.addOtherIncome.type,
    addOtherIncome,
  );
}
function* watchEditOtherIncome() {
  yield effects.takeEvery(
    financialRecordsActions.editOtherIncome.type,
    editOtherIncome,
  );
}
function* watchAddMoneyAndBank() {
  yield effects.takeEvery(
    financialRecordsActions.addMoneyAndBank.type,
    addMoneyAndBank,
  );
}
function* watchNetWorth() {
  yield effects.takeEvery(financialRecordsActions.fetchNetWorth.type, netWorth);
}
function* watchEditMoneyAndBank() {
  yield effects.takeEvery(
    financialRecordsActions.editMoneyAndBank.type,
    editMoneyAndBank,
  );
}
function* watchFetchNetWorthRecordById() {
  yield effects.takeEvery(
    financialRecordsActions.fetchNetWorthRecordById.type,
    fetchNetWorthRecordById,
  );
}
function* watchAddInvestmentPortfolio() {
  yield effects.takeEvery(
    financialRecordsActions.addInvestmentPortfolio.type,
    addInvestmentPortfolio,
  );
}
function* watchEditInvestmentPortfolio() {
  yield effects.takeEvery(
    financialRecordsActions.editInvestmentPortfolio.type,
    editInvestmentPortfolio,
  );
}
function* watchAddPensionAndProvision() {
  yield effects.takeEvery(
    financialRecordsActions.addPensionAndProvision.type,
    addPensionAndProvision,
  );
}
function* watchEditPensionAndProvision() {
  yield effects.takeEvery(
    financialRecordsActions.editPensionAndProvision.type,
    editPensionAndProvision,
  );
}
function* watchAddLiabilities() {
  yield effects.takeEvery(
    financialRecordsActions.addLiabilities.type,
    addLiabilities,
  );
}
function* watchEditLiabilities() {
  yield effects.takeEvery(
    financialRecordsActions.editLiabilities.type,
    editLiabilities,
  );
}
function* watchFetchNetWorthLiabilitiesById() {
  yield effects.takeEvery(
    financialRecordsActions.fetchNetWorthLiabilitiesById.type,
    fetchNetWorthLiabilitiesById,
  );
}
function* watchAddRealEstate() {
  yield effects.takeEvery(
    financialRecordsActions.addRealEstate.type,
    addRealEstate,
  );
}
function* watchEditRealEstate() {
  yield effects.takeEvery(
    financialRecordsActions.editRealEstate.type,
    editRealEstate,
  );
}
function* watchFetchNetWorthRealEstateById() {
  yield effects.takeEvery(
    financialRecordsActions.fetchNetWorthRealEstateById.type,
    fetchNetWorthRealEstateById,
  );
}
function* watchAddOtherAssets() {
  yield effects.takeEvery(
    financialRecordsActions.addOtherAssets.type,
    addOtherAssets,
  );
}
function* watchEditOtherAssets() {
  yield effects.takeEvery(
    financialRecordsActions.editOtherAssets.type,
    editOtherAssets,
  );
}
function* watchAddOtherExpenses() {
  yield effects.takeEvery(
    financialRecordsActions.addOtherExpenses.type,
    addOtherExpenses,
  );
}
function* watchEditOtherExpenses() {
  yield effects.takeEvery(
    financialRecordsActions.editOtherExpenses.type,
    editOtherExpenses,
  );
}
function* watchAddFood() {
  yield effects.takeEvery(financialRecordsActions.addFood.type, addFood);
}
function* watchEditFood() {
  yield effects.takeEvery(financialRecordsActions.editFood.type, editFood);
}
function* watchAddHousing() {
  yield effects.takeEvery(financialRecordsActions.addHousing.type, addHousing);
}
function* watchEditHousing() {
  yield effects.takeEvery(
    financialRecordsActions.editHousing.type,
    editHousing,
  );
}
function* watchAddDistribution() {
  yield effects.takeEvery(
    financialRecordsActions.addDistribution.type,
    addDistribution,
  );
}
function* watchEditDistribution() {
  yield effects.takeEvery(
    financialRecordsActions.editDistribution.type,
    editDistribution,
  );
}
// WATCHERS
export function* watchFinancialRecords() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchAddRent),
    effects.call(watchAddHealthInsurance),
    effects.call(watchNetIncome),
    effects.call(watchFetchNetIncomeRecordById),
    effects.call(watchAddOtherInsurance),
    effects.call(watchEditRent),
    effects.call(watchEditHealthInsurance),
    effects.call(watchAddOtherIncome),
    effects.call(watchAddRentIncome),
    effects.call(watchEditRentIncome),
    effects.call(watchEditOtherInsurance),
    effects.call(watchAddLivingCosts),
    effects.call(watchEditLivingCosts),
    effects.call(watchAddSubscriptions),
    effects.call(watchEditSubscriptions),
    effects.call(watchAddTax),
    effects.call(watchEditTax),
    effects.call(watchAddSalary),
    effects.call(watchFetchSalary),
    effects.call(watchEditSalary),
    effects.call(watchAddDividends),
    effects.call(watchEditDividends),
    effects.call(watchAddInterests),
    effects.call(watchEditInterests),
    effects.call(watchEditOtherIncome),
    effects.call(watchAddMoneyAndBank),
    effects.call(watchNetWorth),
    effects.call(watchEditMoneyAndBank),
    effects.call(watchFetchNetWorthRecordById),
    effects.call(watchDeleteNetRecordById),
    effects.call(watchAddInvestmentPortfolio),
    effects.call(watchEditInvestmentPortfolio),
    effects.call(watchAddPensionAndProvision),
    effects.call(watchEditPensionAndProvision),
    effects.call(watchAddLiabilities),
    effects.call(watchEditLiabilities),
    effects.call(watchFetchNetWorthLiabilitiesById),
    effects.call(watchAddRealEstate),
    effects.call(watchEditRealEstate),
    effects.call(watchFetchNetWorthRealEstateById),
    effects.call(watchAddOtherAssets),
    effects.call(watchEditOtherAssets),
    effects.call(watchAddOtherExpenses),
    effects.call(watchEditOtherExpenses),
    effects.call(watchAddFood),
    effects.call(watchEditFood),
    effects.call(watchAddHousing),
    effects.call(watchEditHousing),
    effects.call(watchAddDistribution),
    effects.call(watchEditDistribution),
    effects.call(watchFetchInvestment),
    // INJECT
  ]);
}
