import prepareActions from '@helpers/prepareActions';
import { createAction } from '@reduxjs/toolkit';

import profileSlice from './slice';

export const profileActions = {
  ...profileSlice.actions,
  fetchProfile: createAction(
    'profile/fetchProfile',
    prepareActions.withNavigate,
  ),
  userEdit: createAction('profile/userEdit', prepareActions.movePromiseToMeta),
  userAddressEdit: createAction(
    'profile/userAddressEdit',
    prepareActions.movePromiseToMeta,
  ),
  userPartnerEdit: createAction(
    'profile/userPartnerEdit',
    prepareActions.movePromiseToMeta,
  ),
  changeEmailRequest: createAction(
    'profile/changeEmailRequest',
    prepareActions.movePromiseToMeta,
  ),
  changeEmailConfirm: createAction(
    'profile/changeEmailConfirm',
    prepareActions.movePromiseToMeta,
  ),
  changePhoneRequest: createAction(
    'profile/changePhoneRequest',
    prepareActions.movePromiseToMeta,
  ),
  enterPhoneCode: createAction(
    'profile/enterPhoneCode',
    prepareActions.movePromiseToMeta,
  ),
  twoStepVerification: createAction(
    'profile/twoStepVerification',
    prepareActions.movePromiseToMeta,
  ),
  phoneForTwoStepVerification: createAction(
    'profile/phoneForTwoStepVerification',
    prepareActions.movePromiseToMeta,
  ),
  userEditPhoto: createAction(
    'profile/userEditPhoto',
    prepareActions.movePromiseToMeta,
  ),
  newPassword: createAction(
    'profile/newPassword',
    prepareActions.movePromiseToMeta,
  ),
  // INJECT
};
