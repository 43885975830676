import * as effects from 'redux-saga/effects';

import { adminViewActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  getTmpToken,
} from './workers';

// IMPORTS
function* watchGetTmpToken() {
  yield effects.takeEvery(adminViewActions.getTmpToken.type, getTmpToken);
}
// WATCHERS
export function* watchAdminView() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchGetTmpToken),
    // INJECT
  ]);
}
