import { Components, Theme } from '@mui/material';

export const MuiOutlinedInput: Partial<Components<Theme>> = {
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        top: 0,
      },
      root: () => {
        return {
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          height: '64px',
          borderRadius: '16px',
          '& legend': {
            display: 'none',
          },
          '&.MuiInputBase-root': {
            paddingRight: '22px',
          },
          '& input': {
            boxSizing: 'border-box',
            height: '100%',
            padding: '25px 16px 12px',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: 'rgba(0,0,0,0.87)',
          },
          '& fieldset': {
            borderColor: 'rgba(0,0,0,0.12)',
            borderWidth: '1px!important',
          },
          '&.Mui-focused': {
            '& fieldset': {
              borderColor: '#1F3557!important',
              borderWidth: '1px!important',
            },
          },
        };
      },
    },
  },
};
