import { createSelector } from 'reselect';

import { RootState } from '@setup/typedefs';

const optionsSelector = (state: RootState) => state.options;

export const getOptions = (key: string) =>
  createSelector([optionsSelector], (options) => {
    return options[key];
  });

export const getFrequency = createSelector([optionsSelector], (options) => {
  const labelMapping = {
    Monthly: { title: 'Monthly', label: '1M', query: 'month' },
    Quarterly: { title: 'Quarterly', label: '3M', query: 'quarter' },
    Biannually: { title: 'Biannually', label: '6M', query: 'half_year' },
  };

  const defaultLabel = { title: 'Yearly', label: '1Y', query: 'year' };

  return options['frequency'].results.map((item) => {
    // @ts-ignore
    const transformedItem = labelMapping[item.label] || defaultLabel;

    return {
      ...item,
      ...transformedItem,
    };
  });
});

export const getSupportSettingsPhone = createSelector(
  [optionsSelector],
  (options) => {
    if (options['settings']) {
      return options['settings'].results
        .filter((item: any) => item.group === 'support')
        .find((item: any) => item.key! === 'phone');
    }
  },
);

export const getSupportSettingsEmail = createSelector(
  [optionsSelector],
  (options) => {
    if (options['settings']) {
      return options['settings'].results
        .filter((item: any) => item.group === 'support')
        .find((item: any) => item.key! === 'email');
    }
  },
);
