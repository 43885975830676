import { Components, Theme } from '@mui/material';

export const MuiList: Partial<Components<Theme>> = {
  MuiList: {
    styleOverrides: {
      root: {
        borderRadius: '16px',
        padding: 0,
        '& .MuiMenuItem-root': {
          fontFamily: 'Poppins, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          textTransform: 'unset',
          height: '52px',
          '&.MuiButtonBase-root': {
            '&.Mui-selected': {
              background: '#E6D4B8',
              borderRadius: '14px',
              margin: '1px',
            },
          },
        },
      },
    },
  },
};
