// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  DocumentsState,
  FillCategoriesActionPayload,
  FillDocumentsActionPayload,
  FillForPreviewActionPayload,
  FillSingleCategoryActionPayload,
  FillChecklistActionPayload,
} from './typedefs';

import { emptyPaginationState } from '@setup/typedefs';

const initialState: DocumentsState = {
  isFetching: false,
  categories: emptyPaginationState,
  subCategories: null,
  forPreview: null,
  documents: null,
  checklist: emptyPaginationState,
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillCategories(state, action: PayloadAction<FillCategoriesActionPayload>) {
      state.categories = action.payload;
    },
    fillSingleCategory(
      state,
      action: PayloadAction<FillSingleCategoryActionPayload>,
    ) {
      state.subCategories = action.payload;
    },
    fillForPreview(state, action: PayloadAction<FillForPreviewActionPayload>) {
      state.forPreview = action.payload;
    },
    clearPreview(state) {
      state.forPreview = null;
    },
    fillDocuments(state, action: PayloadAction<FillDocumentsActionPayload>) {
      state.documents = action.payload;
    },
    fillChecklist(state, action: PayloadAction<FillChecklistActionPayload>) {
      state.checklist = action.payload;
    },
    // INJECT
  },
});

export default documentsSlice;
