import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { privateRoutes } from '@pages/private/routes';
import { nonSubscriberPrivateRoutes } from '@pages/private/nonSubscriberRoutes';
import AppLayout from '@layouts/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '@bus/profile/actions';
import { getProfile } from '@bus/profile/selectors';
import { Box, CircularProgress } from '@mui/material';
import { getOptions } from '@bus/options/selectors';
import { Language } from '@setup/typedefs';
import { optionsActions } from '@bus/options/actions';
import { useTranslation } from 'react-i18next';

// IMPORTS
const SubscriberRoutes = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const navigate = useNavigate();

  const data = useSelector(getOptions('languages'));
  const langList = (data?.results as unknown as Language[]) || [];
  const currentProfileLanguage = langList?.find(
    (item) => item.id === profile?.language,
  )?.code;

  useEffect(() => {
    dispatch(optionsActions.getLanguages());
    dispatch(profileActions.fetchProfile(navigate));
  }, []);

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n
      .changeLanguage(currentProfileLanguage)
      .then(() =>
        localStorage.setItem('language', currentProfileLanguage || 'en'),
      )
      .catch((error) => {
        console.log('changeLanguage error => ', error);
      });
  }, [currentProfileLanguage]);

  useEffect(() => {
    const acceptTermsAndPrivacy = !!profile?.terms_conditions_accepted;
    localStorage.setItem(
      'agreeWithTerms&Privacy',
      acceptTermsAndPrivacy.toString(),
    );
  }, [profile]);

  //TODO: in future get variabl from api
  const isSubscriber = true;

  return profile ? (
    <Box>
      {!isSubscriber && (
        <Box
          sx={{
            background: 'rgb(219, 88, 88)',
            display: 'flex',
            justifyContent: 'center',
            padding: '10px',
          }}>
          unsubscribe mode
        </Box>
      )}
      <Routes>
        {isSubscriber ? (
          <>
            {privateRoutes.map(({ path, Component, Layout, layoutProps }) => (
              <Route element={<Layout {...layoutProps} />} key={path}>
                <Route path={path} element={Component} />
              </Route>
            ))}
            <Route element={<AppLayout />}>
              <Route path={'/*'} element={<Navigate to={'/dashboard'} />} />
            </Route>
          </>
        ) : (
          <>
            {nonSubscriberPrivateRoutes.map(
              ({ path, Component, Layout, layoutProps }) => (
                <Route element={<Layout {...layoutProps} />} key={path}>
                  <Route path={path} element={Component} />
                </Route>
              ),
            )}
            <Route element={<AppLayout />}>
              <Route path={'/*'} element={<Navigate to={'/subscribe'} />} />
            </Route>
          </>
        )}
      </Routes>
    </Box>
  ) : (
    <Box
      width={'100vw'}
      height={'100vh'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}>
      <CircularProgress />
    </Box>
  );
};

export default SubscriberRoutes;
