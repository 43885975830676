/* eslint-disable prettier/prettier */
import layouts from '@layouts/index';
import { commonRoutes } from '@pages/common/routes';
import { book } from '@routes/book';
import { AppRoute } from '@setup/typedefs';
import React, { lazy } from 'react';

const SignUpEmailPage = lazy(()=>import('@pages/public/SignUpEmailPage'));
const SignUpPasswordPage = lazy(()=>import('@pages/public/SignUpPasswordPage'));
const SignInPage = lazy(()=>import('@pages/public/SignInPage'));
const ResetPasswordPage = lazy(()=>import('@pages/public/ResetPasswordPage'));
const WelcomePage = lazy(()=>import('@pages/public/WelcomePage'));
const SignInCodePage = lazy(()=>import('@pages/public/SignInCodePage'));
const CheckYouEmailPage = lazy(()=>import('@pages/common/CheckYouEmailPage'));

// IMPORTS
export const publicRoutes: AppRoute<any>[] = [
  ...[
    {
      path: `${book.signUpEmail}`,
      Component: <SignUpEmailPage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.checkYouEmail}/:registration_token/:email`,
      Component: <CheckYouEmailPage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.signUpPassword}/:id/:registration_token`,
      Component: <SignUpPasswordPage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.signIn}`,
      Component: <SignInPage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.resetPassword}`,
      Component: <ResetPasswordPage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.welcome}`,
      Component: <WelcomePage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.signInCode}`,
      Component: <SignInCodePage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    // INJECT
  ],
  ...commonRoutes,
];
