import { createAction } from '@reduxjs/toolkit';

import proposalUsersSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const proposalUsersActions = {
  ...proposalUsersSlice.actions,
  getProposals: createAction('proposalUsers/getProposals'),
  loadMore: createAction(
    'proposalUsers/loadMore',
    prepareActions.movePromiseToMeta,
  ),
  actionWithProposal: createAction(
    'proposalUsers/actionWithProposal',
    prepareActions.movePromiseToMeta,
  ),
  // INJECT
};
