import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { profileActions } from '../../actions';
import { endpoints } from '@REST/endpoints';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* newPassword({
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<unknown>) {
  try {
    yield effects.put(profileActions.startFetching());

    const response: Response = yield effects.apply(api, api.post, [
      {
        endpoint: endpoints.newPassword,
        headers: { 'content-type': ' application/json' },
      },
    ]);

    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(profileActions.stopFetching());
  }
}
