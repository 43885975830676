import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { proposalUsersActions } from '../../actions';
import { FillProposalsActionPayload } from '../../typedefs';
import { endpoints } from '@REST/endpoints';

export function* getProposals() {
  try {
    yield effects.put(proposalUsersActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      {
        endpoint: endpoints.proposalUsers,
      },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillProposalsActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(proposalUsersActions.fillProposals(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(proposalUsersActions.stopFetching());
  }
}
