import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const PieChart01: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M21.2104 15.8896C20.5742 17.3941 19.5792 18.7198 18.3123 19.7509C17.0454 20.782 15.5452 21.487 13.9428 21.8044C12.3405 22.1217 10.6848 22.0417 9.12055 21.5714C7.55627 21.101 6.13103 20.2547 4.96942 19.1062C3.80782 17.9578 2.94522 16.5423 2.45704 14.9835C1.96886 13.4247 1.86996 11.7701 2.169 10.1642C2.46804 8.55836 3.1559 7.05021 4.17245 5.77161C5.189 4.49301 6.50329 3.4829 8.0004 2.82958M21.2392 8.17275C21.6395 9.13921 21.8851 10.1609 21.9684 11.2004C21.989 11.4572 21.9993 11.5856 21.9483 11.7013C21.9057 11.7979 21.8213 11.8893 21.7284 11.9395C21.6172 11.9996 21.4783 11.9996 21.2004 11.9996H12.8004C12.5204 11.9996 12.3804 11.9996 12.2734 11.9451C12.1793 11.8971 12.1028 11.8207 12.0549 11.7266C12.0004 11.6196 12.0004 11.4796 12.0004 11.1996V2.79958C12.0004 2.52171 12.0004 2.38278 12.0605 2.27156C12.1107 2.17866 12.2021 2.09427 12.2987 2.05167C12.4144 2.00068 12.5428 2.01098 12.7996 2.03156C13.839 2.1149 14.8608 2.36046 15.8272 2.76079C17.0405 3.26333 18.1429 3.99993 19.0715 4.92851C20.0001 5.8571 20.7367 6.95949 21.2392 8.17275Z" />
    </SvgIcon>
  );
};

export default PieChart01;
