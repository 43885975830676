// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  DashboardCategory,
  DashboardState,
  FillRecordTypesActionPayload,
  FillTypesActionPayload,
  FillTypesStatisticsActionPayload,
} from './typedefs';
import { emptyPaginationState, Pagination } from '@setup/typedefs';

const initialState: DashboardState = {
  isFetching: false,
  typesStatistics: [],
  types: null,
  dashboardCategories: {
    netWorth: [],
    netIncome: [],
    costSaving: [],
  },
  recordType: emptyPaginationState,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillCategoriesNetWorth(state, action: PayloadAction<DashboardCategory[]>) {
      state.dashboardCategories.netWorth = action.payload;
    },
    fillCategoriesNetIncome(state, action: PayloadAction<DashboardCategory[]>) {
      state.dashboardCategories.netIncome = action.payload;
    },
    fillCategoriesCostSaving(
      state,
      action: PayloadAction<DashboardCategory[]>,
    ) {
      state.dashboardCategories.costSaving = action.payload;
    },
    fillRecordTypes(
      state,
      action: PayloadAction<Pagination<FillRecordTypesActionPayload[]>>,
    ) {
      state.recordType = action.payload;
    },
    fillTypesStatistics(
      state,
      action: PayloadAction<FillTypesStatisticsActionPayload>,
    ) {
      state.typesStatistics = action.payload;
    },
    fillTypes(state, action: PayloadAction<FillTypesActionPayload>) {
      state.types = action.payload;
    },
    clearRecordTypes(state) {
      state.recordType = emptyPaginationState;
    },
    // INJECT
  },
});

export default dashboardSlice;
