import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { pagesActions } from '../../actions';
import { endpoints } from '@REST/endpoints';
import { FillFaqActionPayload } from '@bus/pages/typedefs';

export function* faq() {
  try {
    yield effects.put(pagesActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: endpoints.faq },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillFaqActionPayload = yield effects.call([response, 'json']);
    yield effects.put(pagesActions.fillFaq(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(pagesActions.stopFetching());
  }
}
