import * as effects from 'redux-saga/effects';

import { documentsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchCategories,
  fetchDocuments,
  fetchSingleCategory,
  saveFiles,
  updateFiles,
  deleteFiles,
  fetchChecklist,
} from './workers';

// IMPORTS
function* watchFetchCategories() {
  yield effects.takeEvery(
    documentsActions.fetchCategories.type,
    fetchCategories,
  );
}
function* watchFetchSingleCategory() {
  yield effects.takeEvery(
    documentsActions.fetchSingleCategory.type,
    fetchSingleCategory,
  );
}
function* watchSaveFiles() {
  yield effects.takeEvery(documentsActions.saveFiles.type, saveFiles);
}
function* watchFetchDocuments() {
  yield effects.takeEvery(documentsActions.fetchDocuments.type, fetchDocuments);
}
function* watchUpdateFiles() {
  yield effects.takeEvery(documentsActions.updateFiles.type, updateFiles);
}
function* watchDeleteFiles() {
  yield effects.takeEvery(documentsActions.deleteFiles.type, deleteFiles);
}
function* watchFetchChecklist() {
  yield effects.takeEvery(documentsActions.fetchChecklist.type, fetchChecklist);
}
// WATCHERS
export function* watchDocuments() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchCategories),
    effects.call(watchFetchSingleCategory),
    effects.call(watchSaveFiles),
    effects.call(watchFetchDocuments),
    effects.call(watchUpdateFiles),
    effects.call(watchDeleteFiles),
    effects.call(watchFetchChecklist),
    // INJECT
  ]);
}
