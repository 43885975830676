import { Components, Theme } from '@mui/material';

export const MuiTooltip: Partial<Components<Theme>> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        background: '#1F3557',
        color: '#ffffff',
        padding: '14px',
        borderRadius: '12px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        textTransform: 'unset',
      },
      popperArrow: {
        '& .MuiTooltip-tooltip': {
          background: '#ffffff',
          borderRadius: '8px',
          boxShadow: '0px 5px 20px 0px rgba(0,0,0,0.3)',
          color: '#000000',
          marginBottom: '0px!important',
          padding: '8px 12px',
          '& .MuiTooltip-arrow': {
            color: '#ffffff',
          },
        },
      },
    },
  },
};
