import prepareActions from '@helpers/prepareActions';
import { createAction } from '@reduxjs/toolkit';

import financialRecordsSlice from './slice';
import {
  FetchNetWorthRealEstateByIdActionPayload,
  FetchNetWorthRecordByIdActionPayload,
  NetIncomeActionPayload,
  NetWorthActionPayload,
} from '@bus/financialRecords/typedefs';
import {
  fetchNetIncomeRecordByIdPayload,
  FetchRecordTypesActionPayload,
} from '@bus/dashboard/typedefs';

export const financialRecordsActions = {
  ...financialRecordsSlice.actions,
  addRent: createAction(
    'financialRecords/addRent',
    prepareActions.movePromiseToMeta,
  ),
  addHealthInsurance: createAction(
    'financialRecords/addHealthInsurance',
    prepareActions.movePromiseToMeta,
  ),
  fetchNetIncome: createAction(
    'financialRecords/fetchNetIncome',
    (payload: NetIncomeActionPayload) => {
      return { payload };
    },
  ),
  deleteNetRecordById: createAction(
    'financialRecords/deleteNetRecordById',
    prepareActions.movePromiseToMeta,
  ),
  fetchNetIncomeRecordById: createAction(
    'financialRecords/fetchNetIncomeRecordById',
    (payload: fetchNetIncomeRecordByIdPayload) => {
      return { payload };
    },
  ),
  addOtherInsurance: createAction(
    'financialRecords/addOtherInsurance',
    prepareActions.movePromiseToMeta,
  ),
  editRent: createAction(
    'financialRecords/editRent',
    prepareActions.movePromiseToMeta,
  ),
  editHealthInsurance: createAction(
    'financialRecords/editHealthInsurance',
    prepareActions.movePromiseToMeta,
  ),
  addRentIncome: createAction(
    'financialRecords/addRentIncome',
    prepareActions.movePromiseToMeta,
  ),
  editRentIncome: createAction(
    'financialRecords/editRentIncome',
    prepareActions.movePromiseToMeta,
  ),
  editOtherInsurance: createAction(
    'financialRecords/editOtherInsurance',
    prepareActions.movePromiseToMeta,
  ),
  addLivingCosts: createAction(
    'financialRecords/addLivingCosts',
    prepareActions.movePromiseToMeta,
  ),
  editLivingCosts: createAction(
    'financialRecords/editLivingCosts',
    prepareActions.movePromiseToMeta,
  ),
  addSubscriptions: createAction(
    'financialRecords/addSubscriptions',
    prepareActions.movePromiseToMeta,
  ),
  editSubscriptions: createAction(
    'financialRecords/editSubscriptions',
    prepareActions.movePromiseToMeta,
  ),
  addTax: createAction(
    'financialRecords/addTax',
    prepareActions.movePromiseToMeta,
  ),
  editTax: createAction(
    'financialRecords/editTax',
    prepareActions.movePromiseToMeta,
  ),
  addSalary: createAction(
    'financialRecords/addSalary',
    prepareActions.movePromiseToMeta,
  ),
  fetchSalary: createAction(
    'financialRecords/fetchSalary',
    (payload: FetchRecordTypesActionPayload) => {
      return { payload };
    },
  ),
  fetchInvestmentPortfolioById: createAction(
    'financialRecords/fetchInvestment',
    (payload: FetchRecordTypesActionPayload) => {
      return { payload };
    },
  ),
  editSalary: createAction(
    'financialRecords/editSalary',
    prepareActions.movePromiseToMeta,
  ),
  addDividends: createAction(
    'financialRecords/addDividends',
    prepareActions.movePromiseToMeta,
  ),
  editDividends: createAction(
    'financialRecords/editDividends',
    prepareActions.movePromiseToMeta,
  ),
  addInterests: createAction(
    'financialRecords/addInterests',
    prepareActions.movePromiseToMeta,
  ),
  editInterests: createAction(
    'financialRecords/editInterests',
    prepareActions.movePromiseToMeta,
  ),
  addOtherIncome: createAction(
    'financialRecords/addOtherIncome',
    prepareActions.movePromiseToMeta,
  ),
  editOtherIncome: createAction(
    'financialRecords/editOtherIncome',
    prepareActions.movePromiseToMeta,
  ),
  addMoneyAndBank: createAction(
    'financialRecords/addMoneyAndBank',
    prepareActions.movePromiseToMeta,
  ),
  fetchNetWorth: createAction(
    'financialRecords/fetchNetWorth',
    (payload: NetWorthActionPayload) => {
      return { payload };
    },
  ),
  editMoneyAndBank: createAction(
    'financialRecords/editMoneyAndBank',
    prepareActions.movePromiseToMeta,
  ),
  fetchNetWorthRecordById: createAction(
    'financialRecords/fetchNetWorthRecordById',
    (payload: FetchNetWorthRecordByIdActionPayload) => {
      return { payload };
    },
  ),
  addInvestmentPortfolio: createAction(
    'financialRecords/addInvestmentPortfolio',
    prepareActions.movePromiseToMeta,
  ),
  editInvestmentPortfolio: createAction(
    'financialRecords/editInvestmentPortfolio',
    prepareActions.movePromiseToMeta,
  ),
  addPensionAndProvision: createAction(
    'financialRecords/addPensionAndProvision',
    prepareActions.movePromiseToMeta,
  ),
  editPensionAndProvision: createAction(
    'financialRecords/editPensionAndProvision',
    prepareActions.movePromiseToMeta,
  ),
  addLiabilities: createAction(
    'financialRecords/addLiabilities',
    prepareActions.movePromiseToMeta,
  ),
  editLiabilities: createAction(
    'financialRecords/editLiabilities',
    prepareActions.movePromiseToMeta,
  ),
  fetchNetWorthLiabilitiesById: createAction(
    'financialRecords/fetchNetWorthLiabilitiesById',
    (payload: FetchNetWorthRecordByIdActionPayload) => {
      return { payload };
    },
  ),
  addRealEstate: createAction(
    'financialRecords/addRealEstate',
    prepareActions.movePromiseToMeta,
  ),
  editRealEstate: createAction(
    'financialRecords/editRealEstate',
    prepareActions.movePromiseToMeta,
  ),
  fetchNetWorthRealEstateById: createAction(
    'financialRecords/fetchNetWorthRealEstateById',
    (payload: FetchNetWorthRealEstateByIdActionPayload) => {
      return { payload };
    },
  ),
  addOtherAssets: createAction(
    'financialRecords/addOtherAssets',
    prepareActions.movePromiseToMeta,
  ),
  editOtherAssets: createAction(
    'financialRecords/editOtherAssets',
    prepareActions.movePromiseToMeta,
  ),
  addOtherExpenses: createAction(
    'financialRecords/addOtherExpenses',
    prepareActions.movePromiseToMeta,
  ),
  editOtherExpenses: createAction(
    'financialRecords/editOtherExpenses',
    prepareActions.movePromiseToMeta,
  ),
  addFood: createAction(
    'financialRecords/addFood',
    prepareActions.movePromiseToMeta,
  ),
  editFood: createAction(
    'financialRecords/editFood',
    prepareActions.movePromiseToMeta,
  ),
  addHousing: createAction(
    'financialRecords/addHousing',
    prepareActions.movePromiseToMeta,
  ),
  editHousing: createAction(
    'financialRecords/editHousing',
    prepareActions.movePromiseToMeta,
  ),
  addDistribution: createAction(
    'financialRecords/addDistribution',
    prepareActions.movePromiseToMeta,
  ),
  editDistribution: createAction(
    'financialRecords/editDistribution',
    prepareActions.movePromiseToMeta,
  ),
  // INJECT
};
