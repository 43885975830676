import * as effects from 'redux-saga/effects';

import { profileActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchProfile,
  userEdit,
  userAddressEdit,
  userPartnerEdit,
  changeEmailRequest,
  changeEmailConfirm,
  changePhoneRequest,
  enterPhoneCode,
  twoStepVerification,
  phoneForTwoStepVerification,
  userEditPhoto,
  newPassword,
} from './workers';

// IMPORTS
function* watchFetchProfile() {
  yield effects.takeEvery(profileActions.fetchProfile.type, fetchProfile);
}
function* watchUserEdit() {
  yield effects.takeEvery(profileActions.userEdit.type, userEdit);
}
function* watchUserAddressEdit() {
  yield effects.takeEvery(profileActions.userAddressEdit.type, userAddressEdit);
}
function* watchUserPartnerEdit() {
  yield effects.takeEvery(profileActions.userPartnerEdit.type, userPartnerEdit);
}
function* watchChangeEmailRequest() {
  yield effects.takeEvery(
    profileActions.changeEmailRequest.type,
    changeEmailRequest,
  );
}
function* watchChangeEmailConfirm() {
  yield effects.takeEvery(
    profileActions.changeEmailConfirm.type,
    changeEmailConfirm,
  );
}
function* watchChangePhoneRequest() {
  yield effects.takeEvery(
    profileActions.changePhoneRequest.type,
    changePhoneRequest,
  );
}
function* watchEnterPhoneCode() {
  yield effects.takeEvery(profileActions.enterPhoneCode.type, enterPhoneCode);
}
function* watchTwoStepVerification() {
  yield effects.takeEvery(
    profileActions.twoStepVerification.type,
    twoStepVerification,
  );
}
function* watchPhoneForTwoStepVerification() {
  yield effects.takeEvery(
    profileActions.phoneForTwoStepVerification.type,
    phoneForTwoStepVerification,
  );
}
function* watchUserEditPhoto() {
  yield effects.takeEvery(profileActions.userEditPhoto.type, userEditPhoto);
}
function* watchNewPassword() {
  yield effects.takeEvery(profileActions.newPassword.type, newPassword);
}
// WATCHERS
export function* watchProfile() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchProfile),
    effects.call(watchUserEdit),
    effects.call(watchUserAddressEdit),
    effects.call(watchUserPartnerEdit),
    effects.call(watchChangeEmailRequest),
    effects.call(watchChangeEmailConfirm),
    effects.call(watchChangePhoneRequest),
    effects.call(watchEnterPhoneCode),
    effects.call(watchTwoStepVerification),
    effects.call(watchPhoneForTwoStepVerification),
    effects.call(watchUserEditPhoto),
    effects.call(watchNewPassword),
    // INJECT
  ]);
}
