export const styles = {
  navigationMenu: {
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '310px',
    width: 'calc(100% - 80px)',
    height: '48px',
    borderRadius: '16px',
    overflow: 'hidden',
    background: '#E6D4B8',
    padding: '2px 16px',
  },
};
