/* eslint-disable prettier/prettier */
import React, { lazy } from 'react';

import layouts from '@layouts/index';
import { commonRoutes } from '@pages/common/routes';
import { book } from '@routes/book';
import { AppRoute } from '@setup/typedefs';
const SubscribePage = lazy(()=>import('@pages/private/SubscribePage'));
const DocumentsPage = lazy(()=>import('@pages/private/DocumentsPage'));
const DashboardPage = lazy(()=>import('@pages/private/DashboardPage'));
const DocumentsSingleCategoryPage = lazy(()=>import('@pages/private/SingleDocumentPage'));
const TodoPage = lazy(()=>import('@pages/private/TodoPage'));
const SupportPage = lazy(()=>import('@pages/private/SupportPage'));
const PreviewPage = lazy(()=>import('@pages/private/PreviewPage'));
const PreviewSamplePage = lazy(()=>import('@pages/private/PreviewSamplePage'));
const PreviewDocumentFilledPage = lazy(()=>import('src/pages/private/PreviewDocumentFilledPage'));
const QuestionsNamePage = lazy(()=>import('src/pages/private/QuestionsNamePage'));
const QuestionsBirthdayPage = lazy(()=>import('@pages/private/QuestionsBirthdayPage'));
const QuestionsCivilStatusPage = lazy(()=>import('@pages/private/QuestionsCivilStatusPage'));
const QuestionsPartnersBirthdayPage = lazy(()=>import('@pages/private/QuestionsPartnersBirthdayPage'));
const QuestionsDependableChildrenPage = lazy(()=>import('@pages/private/QuestionsDependableChildrenPage'));
const QuestionsAddressPage = lazy(()=>import('@pages/private/QuestionsAddressPage'));
const QuestionsPartnerNamePage = lazy(()=>import('@pages/private/QuestionsPartnerNamePage'));
const ProfilePage = lazy(()=>import('@pages/private/ProfilePage'));
const EditPersonalInformationPage = lazy(()=>import('@pages/private/EditPersonalInformationPage'));
const EditBirthdayPage = lazy(()=>import('@pages/private/EditBirthdayPage'));
const EditResidentialAddressPage = lazy(()=>import('@pages/private/EditResidentialAddressPage'));
const EditCivilStatusPage = lazy(()=>import('@pages/private/EditCivilStatusPage'));
const EditPartnerPage = lazy(()=>import('@pages/private/EditPartnerPage'));
const EditDependableChildrenPage = lazy(()=>import('@pages/private/EditDependableChildrenPage'));
const EditContactInformationPage = lazy(()=>import('@pages/private/EditMailPage'));
const EmailChangePage = lazy(()=>import('@pages/private/EmailChangePage'));
const EditPhonePage = lazy(()=>import('src/pages/private/EditPhonePage'));
const PhoneCodePage = lazy(()=>import('@pages/private/PhoneCodePage'));
const TwoStepVerificationPage = lazy(()=>import('@pages/private/TwoStepVerificationPage'));
const CodeForTwoStepVerificationPage = lazy(()=>import('@pages/private/CodeForTwoStepVerificationPage'));
const CheckYouEmailPage = lazy(()=>import('@pages/common/CheckYouEmailPage'));
const AddRentPage = lazy(()=>import('@pages/private/AddRentPage'));
const AddHealthInsurancePage = lazy(()=>import('@pages/private/AddHealthInsurancePage'));
const FinancialRecordsListPage = lazy(()=>import('@pages/private/FinancialRecordsListPage'));
const EditRentPage = lazy(()=>import('@pages/private/EditRentPage'));
const AddOtherInsurancePage = lazy(()=>import('@pages/private/AddOtherInsurancePage'));
const EditHealthInsurancePage = lazy(()=>import('@pages/private/EditHealthInsurancePage'));
const EditOtherInsurancePage = lazy(()=>import('@pages/private/EditOtherInsurancePage'));
const AddRentIncomePage = lazy(()=>import('src/pages/private/AddRentIncomePage'));
const EditRentIncomePage = lazy(()=>import('@pages/private/EditRentIncomePage'));
const AddLivingCostsPage = lazy(()=>import('@pages/private/AddLivingCostsPage'));
const AddSubscriptionsPage = lazy(()=>import('@pages/private/AddSubscriptionsPage'));
const EditLivingCostsPage = lazy(()=>import('@pages/private/EditLivingCostsPage'));
const EditSubscriptionsPage = lazy(()=>import('@pages/private/EditSubscriptionsPage'));
const AddTaxPage = lazy(()=>import('@pages/private/AddTaxPage'));
const EditTaxPage = lazy(()=>import('@pages/private/EditTaxPage'));
const AddSalaryPage = lazy(()=>import('@pages/private/AddSalaryPage'));
const EditSalaryPage = lazy(()=>import('@pages/private/EditSalaryPage'));
const AddDividendsPage = lazy(()=>import('@pages/private/AddDividendsPage'));
const EditDividendsPage = lazy(()=>import('@pages/private/EditDividendsPage'));
const AddInterestsPage = lazy(()=>import('@pages/private/AddInterestsPage'));
const EditInterestsPage = lazy(()=>import('@pages/private/EditInterestsPage'));
const AddOtherIncomePage = lazy(()=>import('@pages/private/AddOtherIncomePage'));
const EditOtherIncomePage = lazy(()=>import('@pages/private/EditOtherIncomePage'));
const DocumentPreviewPage = lazy(()=>import('@pages/private/DocumentPreviewPage'));
const AcceptProposalDonePage = lazy(()=>import('@pages/private/AcceptProposalDonePage'));
const AddMoneyAndBankPage = lazy(()=>import('@pages/private/AddMoneyAndBankPage'));
const EditMoneyAndBankPage = lazy(()=>import('@pages/private/EditMoneyAndBankPage'));
const AddInvestmentPortfolioPage = lazy(()=>import('@pages/private/AddInvestmentPortfolioPage'));
const EditInvestmentPortfolioPage = lazy(()=>import('@pages/private/EditInvestmentPortfolioPage'));
const AddPensionAndProvisionPage = lazy(()=>import('@pages/private/AddPensionAndProvisionPage'));
const EditPensionAndProvisionPage = lazy(()=>import('@pages/private/EditPensionAndProvisionPage'));
const AddLiabilitiesPage = lazy(()=>import('@pages/private/AddLiabilitiesPage'));
const EditLiabilitiesPage = lazy(()=>import('@pages/private/EditLiabilitiesPage'));
const AddRealEstatePage = lazy(()=>import('@pages/private/AddRealEstatePage'));
const EditRealEstatePage = lazy(()=>import('@pages/private/EditRealEstatePage'));
const AddOtherAssetsPage = lazy(()=>import('@pages/private/AddOtherAssetsPage'));
const EditOtherAssetsPage = lazy(()=>import('@pages/private/EditOtherAssetsPage'));
const DocumentsPrePage = lazy(()=>import('@pages/private/DocumentsPrePage'));
const PreviewSampleChecklistPage = lazy(()=>import('@pages/private/PreviewSampleChecklistPage'));
const ChecklistPage = lazy(()=>import('@pages/private/ChecklistPage'));
const AddOtherExpensesPage = lazy(()=>import('@pages/private/AddOtherExpensesPage'));
const EditOtherExpensesPage = lazy(()=>import('@pages/private/EditOtherExpensesPage'));
const AddDistributionPage = lazy(()=>import('@pages/private/AddDistributionPage'));
const EditDistributionPage = lazy(()=>import('@pages/private/EditDistributionPage'));
const AddHousingPage = lazy(()=>import('@pages/private/AddHousingPage'));
const EditHousingPage = lazy(()=>import('@pages/private/EditHousingPage'));
const AddFoodPage = lazy(()=>import('@pages/private/AddFoodPage'));
const EditFoodPage = lazy(()=>import('@pages/private/EditFoodPage'));

// IMPORTS

export const privateRoutes: AppRoute<any>[] = [
  ...[
    {
      path: `${book.documents}`,
      Component: <DocumentsPage />,
      Layout: layouts.NavigationMenuLayout,
      layoutProps: {},
    },
    {
      path: `${book.dashboard}`,
      Component: <DashboardPage />,
      Layout: layouts.NavigationMenuLayout,
      layoutProps: {},
    },
    {
      path: `${book.documents}/:id`,
      Component: <DocumentsSingleCategoryPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.todo}`,
      Component: <TodoPage />,
      Layout: layouts.NavigationMenuLayout,
      layoutProps: {},
    },
    {
      path: `${book.support}`,
      Component: <SupportPage />,
      Layout: layouts.NavigationMenuLayout,
      layoutProps: {},
    },
    {
      path: `${book.preview}`,
      Component: <PreviewPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.previewSample}/:categoryId/:subcategoryId`,
      Component: <PreviewSamplePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.previewFilledDocument}/:documentId`,
      Component: <PreviewDocumentFilledPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.questionsName}`,
      Component: <QuestionsNamePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.questionsBirthday}`,
      Component: <QuestionsBirthdayPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.questionsCivilStatus}`,
      Component: <QuestionsCivilStatusPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.questionsDependableChildren}`,
      Component: <QuestionsDependableChildrenPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.questionsAddress}`,
      Component: <QuestionsAddressPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.questionsPartnerName}`,
      Component: <QuestionsPartnerNamePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.questionsPartnersBirthday}`,
      Component: <QuestionsPartnersBirthdayPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.profile}`,
      Component: <ProfilePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editPersonalInformation}`,
      Component: <EditPersonalInformationPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editBirthday}`,
      Component: <EditBirthdayPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editResidentialAddress}`,
      Component: <EditResidentialAddressPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editCivilStatus}`,
      Component: <EditCivilStatusPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editPartner}`,
      Component: <EditPartnerPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editDependableChildren}`,
      Component: <EditDependableChildrenPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editMail}`,
      Component: <EditContactInformationPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.emailChange}/:activation_token`,
      Component: <EmailChangePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editPhone}`,
      Component: <EditPhonePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.phoneCode}/:phone`,
      Component: <PhoneCodePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.twoStepVerification}`,
      Component: <TwoStepVerificationPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.codeForTwoStepVerification}/:phone`,
      Component: <CodeForTwoStepVerificationPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.checkYouEmail}/:registration_token/:email`,
      Component: <CheckYouEmailPage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.financialRecordsList}/:category_id/:slug/:parent_id`,
      Component: <FinancialRecordsListPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addRent}/:category_id/:parent_id`,
      Component: <AddRentPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addHealthInsurance}/:category_id/:parent_id`,
      Component: <AddHealthInsurancePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addOtherInsurance}/:category_id/:parent_id`,
      Component: <AddOtherInsurancePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editRent}/:category_id/:parent_id/:id`,
      Component: <EditRentPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editHealthInsurance}/:category_id/:parent_id/:id`,
      Component: <EditHealthInsurancePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editOtherInsurance}/:category_id/:parent_id/:id`,
      Component: <EditOtherInsurancePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addRentIncome}/:category_id/:parent_id`,
      Component: <AddRentIncomePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editRentIncome}/:category_id/:parent_id/:id`,
      Component: <EditRentIncomePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addLivingCosts}/:category_id/:parent_id`,
      Component: <AddLivingCostsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editLivingCosts}/:category_id/:parent_id/:id`,
      Component: <EditLivingCostsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addSubscriptions}/:category_id/:parent_id`,
      Component: <AddSubscriptionsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editSubscriptions}/:category_id/:parent_id/:id`,
      Component: <EditSubscriptionsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addTax}/:category_id/:parent_id`,
      Component: <AddTaxPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editTax}/:category_id/:parent_id/:id`,
      Component: <EditTaxPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addSalary}/:category_id/:parent_id`,
      Component: <AddSalaryPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editSalary}/:category_id/:parent_id/:id`,
      Component: <EditSalaryPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addDividends}/:category_id/:parent_id`,
      Component: <AddDividendsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editDividends}/:category_id/:parent_id/:id`,
      Component: <EditDividendsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addInterests}/:category_id/:parent_id`,
      Component: <AddInterestsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editInterests}/:category_id/:parent_id/:id`,
      Component: <EditInterestsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addOtherIncome}/:category_id/:parent_id`,
      Component: <AddOtherIncomePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editOtherIncome}/:category_id/:parent_id/:id`,
      Component: <EditOtherIncomePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.documentPreview}`,
      Component: <DocumentPreviewPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.acceptProposalDone}`,
      Component: <AcceptProposalDonePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addMoneyAndBank}/:category_id/:parent_id`,
      Component: <AddMoneyAndBankPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editMoneyAndBank}/:category_id/:parent_id/:id`,
      Component: <EditMoneyAndBankPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addInvestmentPortfolio}/:category_id/:parent_id`,
      Component: <AddInvestmentPortfolioPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editInvestmentPortfolio}/:category_id/:parent_id/:id`,
      Component: <EditInvestmentPortfolioPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addPensionAndProvision}/:category_id/:parent_id`,
      Component: <AddPensionAndProvisionPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editPensionAndProvision}/:category_id/:parent_id/:id`,
      Component: <EditPensionAndProvisionPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addLiabilities}/:category_id/:parent_id`,
      Component: <AddLiabilitiesPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editLiabilities}/:category_id/:parent_id/:id`,
      Component: <EditLiabilitiesPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addRealEstate}/:category_id/:parent_id`,
      Component: <AddRealEstatePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editRealEstate}/:category_id/:parent_id/:id`,
      Component: <EditRealEstatePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addOtherAssets}/:category_id/:parent_id`,
      Component: <AddOtherAssetsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editOtherAssets}/:category_id/:parent_id/:id`,
      Component: <EditOtherAssetsPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.documentsPre}/:id`,
      Component: <DocumentsPrePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.checklist}`,
      Component: <ChecklistPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.previewSampleChecklist}/:primeId/:id`,
      Component: <PreviewSampleChecklistPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addOtherExpenses}/:category_id/:parent_id`,
      Component: <AddOtherExpensesPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editOtherExpenses}/:category_id/:parent_id/:id`,
      Component: <EditOtherExpensesPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.subscribe}`,
      Component: <SubscribePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addDistribution}/:category_id/:parent_id`,
      Component: <AddDistributionPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editDistribution}/:category_id/:parent_id/:id`,
      Component: <EditDistributionPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addHousing}/:category_id/:parent_id`,
      Component: <AddHousingPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editHousing}/:category_id/:parent_id/:id`,
      Component: <EditHousingPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.addFood}/:category_id/:parent_id`,
      Component: <AddFoodPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editFood}/:category_id/:parent_id/:id`,
      Component: <EditFoodPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    // INJECT
  ],
  ...commonRoutes,
];
