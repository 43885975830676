import * as effects from 'redux-saga/effects';

import { dashboardActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchCategories,
  fetchRecordTypes,
  fetchTypesStatistics,
  fetchTypes,
} from './workers';

// IMPORTS
function* watchGetCategories() {
  yield effects.takeEvery(
    dashboardActions.fetchCategories.type,
    fetchCategories,
  );
}
function* watchFetchRecordTypes() {
  yield effects.takeEvery(
    dashboardActions.fetchRecordTypes.type,
    fetchRecordTypes,
  );
}
function* watchFetchTypesStatistics() {
  yield effects.takeEvery(
    dashboardActions.fetchTypesStatistics.type,
    fetchTypesStatistics,
  );
}
function* watchFetchTypes() {
  yield effects.takeEvery(dashboardActions.getTypes.type, fetchTypes);
}
// WATCHERS
export function* watchDashboard() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchGetCategories),
    effects.call(watchFetchRecordTypes),
    effects.call(watchFetchTypesStatistics),
    effects.call(watchFetchTypes),
    // INJECT
  ]);
}
