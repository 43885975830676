import { Components } from '@mui/material';

export const MuiSelect: Partial<Components> = {
  MuiSelect: {
    styleOverrides: {
      select: {
        minWidth: '50px',
        '&.MuiOutlinedInput-input': {
          padding: '25px 16px 12px',
        },
      },
    },
  },
};
