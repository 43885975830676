import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

import { authActions } from '../../actions';
import { SignInCodeActionPayload } from '../../typedefs';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '@core/FinalForm/getServerErrors';
import { call } from 'redux-saga/effects';
import { endpoints } from '@REST/endpoints';

export function* signInCode({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<SignInCodeActionPayload>) {
  try {
    yield effects.put(authActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      {
        endpoint: endpoints.verifyCode,
        body,
        headers: { 'content-type': ' application/json' },
      },
    ]);

    if (!response.ok) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);

      reject(formErrors);
      yield throwError(response);
    }

    const data: unknown & {
      access: string;
      refresh: string;
    } & ServerFormErrors = yield effects.call([response, 'json']);

    const { access, refresh } = data;

    window.localStorage.setItem('accessToken', JSON.stringify(access));
    window.localStorage.setItem('refreshToken', JSON.stringify(refresh));

    yield effects.put(authActions.authenticate());

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(authActions.stopFetching());
  }
}
