import { SnackbarProvider } from 'notistack';

import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import { Box, CssBaseline, ThemeProvider } from '@mui/material';

import { registeredModals } from '@components/modals/registeredModals';
import { Modals } from '@core/Modal';
import { createStore } from '@setup/configureStore';
import { useAppTheme } from '@themes/main';

import './App.css';
import Routers from './routes';
import '../src/helpers/i18n';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

export const store = createStore();

function App() {
  const theme = useAppTheme();
  const [adminView, setAdminView] = useState(false);
  const adminViewTmp = window.sessionStorage.getItem('adminView');
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    setAdminView(adminViewTmp === 'true');
  }, [adminViewTmp]);

  return (
    <Box pt={adminView ? '54px' : '0'}>
      {adminView && (
        <Box
          p={'15px'}
          sx={{
            backgroundColor: 'rgba(219, 88, 88)',
            pointerEvents: 'none',
            position: 'fixed',
            top: 0,
            width: '100%',
            left: 0,
            zIndex: 100,
          }}>
          <p style={{ margin: 0, textAlign: 'center' }}>
            You are now in administrator mode
          </p>
        </Box>
      )}
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        adapterLocale={currentLanguage}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <>
              <Modals registeredModals={registeredModals} />
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}>
                <Routers />
              </SnackbarProvider>
            </>
          </Provider>
        </ThemeProvider>
      </LocalizationProvider>
    </Box>
  );
}

export default App;
