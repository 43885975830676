// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import state from './initialState.json';

// eslint-disable-next-line prettier/prettier
import {
  OptionsState,
  FillLanguagesActionPayload,
  FillSettingsActionPayload,
} from './typedefs';

const initialState: OptionsState = {
  ...state,
};

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    fillLanguages(state, action: PayloadAction<FillLanguagesActionPayload>) {
      state.languages = action.payload;
    },
    fillSettings(state, action: PayloadAction<FillSettingsActionPayload>) {
      state.settings = action.payload;
    },
    // INJECT
  },
});

export default optionsSlice;
