import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { adminViewActions } from '../../actions';
import { GetTmpTokenActionPayload } from '../../typedefs';
import { endpoints } from '@REST/endpoints';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '@core/FinalForm/getServerErrors';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { call } from 'redux-saga/effects';

export function* getTmpToken({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<GetTmpTokenActionPayload>) {
  try {
    yield effects.put(adminViewActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, {
      hashed_token: payload,
    });
    const response: Response = yield effects.apply(api, api.post, [
      {
        endpoint: endpoints.getTmpToken,
        body,
        headers: { 'content-type': ' application/json' },
      },
    ]);

    if (!response.ok) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);
      if (formErrors.detail) {
        reject(formErrors.detail);
      } else {
        reject(`status: ${response.status}`);
      }

      yield throwError(response);
    }

    const data: unknown & {
      access: string;
      refresh: string;
      two_factor_authentication_enabled: boolean;
    } & ServerFormErrors = yield effects.call([response, 'json']);

    const { access, refresh } = data;

    window.sessionStorage.setItem('accessToken', JSON.stringify(access));
    window.sessionStorage.setItem('refreshToken', JSON.stringify(refresh));
    window.sessionStorage.setItem('adminView', JSON.stringify(true));

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(adminViewActions.stopFetching());
  }
}
