import { Components, Theme } from '@mui/material';

export const MuiPaper: Partial<Components<Theme>> = {
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '16px',
        '& .MuiTypography-overline': {
          // color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '12px',
          textTransform: 'uppercase',
        },
        '& .MuiPickersCalendarHeader-label': {
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '14px',
        },
        '& .MuiDayPicker-weekDayLabel': {
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: '16px',
          fontWeight: 400,
        },
        '& .MuiPickersDay-root': {
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '& .MuiPickersToolbar-content': {
          '& button': {
            display: 'none',
          },
        },
        '& .MuiDialogActions-root': {
          '& button': {
            background: 'unset',
            color: '#1F3557',
            border: 'unset',
            boxShadow: 'none',
          },
        },
      },
    },
  },
};
