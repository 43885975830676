import * as effects from 'redux-saga/effects';

import { authActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  authenticate,
  signUpEmail,
  signUpPassword,
  signIn,
  resetPassword,
  setNewPassword,
  logout,
  signInCode,
  sendEmailAgain,
  sendSmsCodeAgain,
} from './workers';

// IMPORTS

function* watchAuthenticate() {
  yield effects.takeEvery(authActions.authenticate.type, authenticate);
}
function* watchSignUpEmail() {
  yield effects.takeEvery(authActions.signUpEmail.type, signUpEmail);
}
function* watchSignUpPassword() {
  yield effects.takeEvery(authActions.signUpPassword.type, signUpPassword);
}
function* watchSignIn() {
  yield effects.takeEvery(authActions.signIn.type, signIn);
}
function* watchResetPassword() {
  yield effects.takeEvery(authActions.resetPassword.type, resetPassword);
}
function* watchSetNewPassword() {
  yield effects.takeEvery(authActions.setNewPassword.type, setNewPassword);
}
function* watchLogout() {
  yield effects.takeEvery(authActions.logout.type, logout);
}
function* watchSendEmailAgain() {
  yield effects.takeEvery(authActions.sendEmailAgain.type, sendEmailAgain);
}
function* watchSignInCode() {
  yield effects.takeEvery(authActions.signInCode.type, signInCode);
}
function* watchSendSmsCodeAgain() {
  yield effects.takeEvery(authActions.sendSmsCodeAgain.type, sendSmsCodeAgain);
}
// WATCHERS
export function* watchAuth() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchAuthenticate),
    effects.call(watchSignUpEmail),
    effects.call(watchSignUpPassword),
    effects.call(watchSignIn),
    effects.call(watchResetPassword),
    effects.call(watchSetNewPassword),
    effects.call(watchLogout),
    effects.call(watchSendEmailAgain),
    effects.call(watchSignInCode),
    effects.call(watchSendSmsCodeAgain),
    // INJECT
  ]);
}
