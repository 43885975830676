import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { documentsActions } from '../../actions';
import { endpoints } from '@REST/endpoints';
import { FillChecklistActionPayload } from '@bus/documents/typedefs';

export function* fetchChecklist() {
  try {
    yield effects.put(documentsActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: endpoints.checklist },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillChecklistActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(documentsActions.fillChecklist(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(documentsActions.stopFetching());
  }
}
