import authSlice from '@bus/auth/slice';
import optionsSlice from '@bus/options/slice';
import profileSlice from '@bus/profile/slice';
import uiSlice from '@bus/ui/slice';
import modalSlice from '@core/Modal/state/slice';
import { combineReducers } from '@reduxjs/toolkit';
import documentsSlice from '@bus/documents/slice';
import pagesSlice from '@bus/pages/slice';
import dashboardSlice from '@bus/dashboard/slice';
import financialRecordsSlice from '@bus/financialRecords/slice';
import proposalUsersSlice from '@bus/proposalUsers/slice';
import adminViewSlice from '@bus/adminView/slice';

// IMPORTS

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  options: optionsSlice.reducer,
  ui: uiSlice.reducer,
  profile: profileSlice.reducer,
  modal: modalSlice.reducer,
  documents: documentsSlice.reducer,
  pages: pagesSlice.reducer,
  dashboard: dashboardSlice.reducer,
  financialRecords: financialRecordsSlice.reducer,
  proposalUsers: proposalUsersSlice.reducer,
  adminView: adminViewSlice.reducer,
  // INJECT
});
