import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { useTranslation } from 'react-i18next';

type ConfirmOrCancelProps = {
  text: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
};

export const ConfirmOrCancel: React.FC<
  ModalTypes.ModalComponentProps<ConfirmOrCancelProps>
> = ({ title, closeFn, onConfirm, text, cancelButton, confirmButton }) => {
  const { t } = useTranslation();
  const closeModal = () => {
    closeFn();
  };

  return (
    <Box sx={styles.root}>
      <Box>
        {title && (
          <Box>
            <Typography variant={'h5'}>{title}</Typography>
          </Box>
        )}
        {text && (
          <Box>
            <Typography variant={'body2'}>{text}</Typography>
          </Box>
        )}
      </Box>
      <Box display={'flex'} gap={'10px'} width={'100%'}>
        <Button
          sx={{ flexBasis: '50%' }}
          onClick={closeModal}
          variant={'textButton'}>
          {cancelButton?.text || t('Cancel')}
        </Button>
        <Button
          sx={{ flexBasis: '50%' }}
          onClick={() => {
            onConfirm();
            closeModal();
          }}
          variant={'mainButton'}>
          {confirmButton?.text || t('Confirm')}
        </Button>
      </Box>
    </Box>
  );
};
