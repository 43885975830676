import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import { styles } from './styles';
import NavigationMenu from '@components/common/NavigationMenu';
import GreetingUser from '@components/pages/private/DocumentsPage/GreetingUser';

export type NavigationMenuLayoutProps = {};

const NavigationMenuLayout: React.FC<NavigationMenuLayoutProps> = () => {
  return (
    <Box sx={styles.root}>
      <Suspense fallback={<>...loading</>}>
        <GreetingUser />
        <Outlet />
        <NavigationMenu />
      </Suspense>
    </Box>
  );
};

export default NavigationMenuLayout;
