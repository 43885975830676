import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { financialRecordsActions } from '../../actions';
import {
  FetchNetWorthRealEstateByIdActionPayload,
  RealEstateRecord,
} from '../../typedefs';
import { endpoints } from '@REST/endpoints';

export function* fetchNetWorthRealEstateById({
  payload,
}: PayloadAction<FetchNetWorthRealEstateByIdActionPayload>) {
  const { id } = payload;
  try {
    yield effects.put(financialRecordsActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `${endpoints.netWorthRealEstate}${id}/` },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: RealEstateRecord = yield effects.call([response, 'json']);

    yield effects.put(financialRecordsActions.fillNetRecord(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(financialRecordsActions.stopFetching());
  }
}
