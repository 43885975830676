export const styles = {
  root: {
    maxWidth: '390px',
    minHeight: '100vh',
    margin: '0 auto',
    padding: '20px 20px 127px',
    background: '#F8F3EE',
    display: 'flex',
    flexDirection: 'column',
    '& > div:nth-of-type(2)': {
      flex: 1,
    },
  },
};
