export const styles = {
  navigationMenuItem: {
    borderRadius: '16px',
    gap: 0,
    overflow: 'hidden',
    padding: '0',
    minWidth: 'unset',
  },
  navigationMenuItemActive: {
    padding: '12px 16px',
    background: '#FCFAF6',
    gap: '10px',
    '&:hover': {
      background: '#FCFAF6',
    },
  },
};
