import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { optionsActions } from '../../actions';
import { endpoints } from '@REST/endpoints';
import { FillSettingsActionPayload } from '@bus/options/typedefs';

export function* fetchSettings() {
  try {
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: endpoints.getSettings, unsafe: true },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillSettingsActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(optionsActions.fillSettings(data));
  } catch (e) {
    yield handleError(e);
  }
}
