// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  PagesState,
  FillSinglePageActionPayload,
  FillFaqActionPayload,
} from './typedefs';
import { emptyPaginationState } from '@setup/typedefs';

const initialState: PagesState = {
  isFetching: false,
  singlePage: null,
  faqPage: emptyPaginationState,
};

const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillSinglePage(state, action: PayloadAction<FillSinglePageActionPayload>) {
      state.singlePage = action.payload;
    },
    clearSinglePage(state) {
      state.singlePage = null;
    },
    fillFaq(state, action: PayloadAction<FillFaqActionPayload>) {
      state.faqPage = action.payload;
    },
    // INJECT
  },
});

export default pagesSlice;
