import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { publicRoutes } from '@pages/public/routes';
import { book } from '@routes/book';
import AuthLayout from '@layouts/AuthLayout';

const PublicRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const noAgree = localStorage.getItem('agreeWithTerms&Privacy') !== 'true';
    [book.signUpEmail].includes(location?.pathname) &&
      noAgree &&
      navigate(book.termsPrivacy);
  }, [location]);

  return (
    <>
      <Routes>
        {publicRoutes.map(({ path, Component, Layout, layoutProps }) => (
          <Route element={<Layout {...layoutProps} />} key={path}>
            <Route path={path} element={Component} />
          </Route>
        ))}
        <Route element={<AuthLayout />}>
          <Route path={'/*'} element={<Navigate to={book.welcome} />} />
        </Route>
      </Routes>
    </>
  );
};

export default PublicRoutes;
