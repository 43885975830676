import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { dashboardActions } from '../../actions';
import { DashboardCategory, GetCategoriesActionPayload } from '../../typedefs';
import { endpoints } from '@REST/endpoints';

export function* fetchCategories({
  payload,
}: PayloadAction<GetCategoriesActionPayload>) {
  const { dashboard_type, filter_period } = payload;
  try {
    yield effects.put(dashboardActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      {
        endpoint: `${endpoints.dashboardCategories}?dashboard_type=${dashboard_type}&filter_period=${filter_period}`,
      },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: DashboardCategory[] = yield effects.call([response, 'json']);

    if (dashboard_type === 1) {
      yield effects.put(dashboardActions.fillCategoriesNetWorth(data));
    }

    if (dashboard_type === 2) {
      yield effects.put(dashboardActions.fillCategoriesNetIncome(data));
    }

    if (dashboard_type === 3) {
      yield effects.put(dashboardActions.fillCategoriesCostSaving(data));
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(dashboardActions.stopFetching());
  }
}
