import { Interpolation, TypographyProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const typographyVariants:
  | {
      props: Partial<TypographyProps<'span', {}>>;
      style: Interpolation<{ theme: Theme }>;
    }[]
  | undefined = [
  {
    props: {
      variant: 'button',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0px',
      textTransform: 'unset',
    },
  },
  {
    props: {
      variant: 'h6',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15000000596046448px',
    },
  },
  {
    props: {
      variant: 'h5',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '24px',
      letterSpacing: '0.18000000715255737px',
    },
  },
  {
    props: {
      variant: 'h4',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '34px',
      lineHeight: '40px',
      letterSpacing: '0px',
    },
  },
  {
    props: {
      variant: 'h3',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '48px',
      lineHeight: '56px',
      letterSpacing: '0px',
    },
  },
  {
    props: {
      variant: 'h2',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '60px',
      lineHeight: '72px',
      letterSpacing: '-0.5px',
    },
  },
  {
    props: {
      variant: 'h1',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '96px',
      lineHeight: '112px',
      letterSpacing: '-1.5px',
    },
  },
  {
    props: {
      variant: 'caption',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4000000059604645px',
    },
  },
  {
    props: {
      variant: 'body2',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
  },
  {
    props: {
      variant: 'body1',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.25px',
    },
  },
  {
    props: {
      variant: 'subtitle2',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.10000000149011612px',
    },
  },
  {
    props: {
      variant: 'subtitle1',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15000000596046448px',
    },
  },
  {
    props: {
      variant: 'overline',
    },
    style: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '1.5px',
    },
  },
];

// types
declare module '@mui/material/Typography' {
  export interface TypographyPropsVariantOverrides {}
}

declare module '@mui/material/styles' {
  export interface TypographyVariantsOptions {}
}
