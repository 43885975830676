import { createAction } from '@reduxjs/toolkit';

import adminViewSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const adminViewActions = {
  ...adminViewSlice.actions,
  getTmpToken: createAction(
    'adminView/getTmpToken',
    prepareActions.movePromiseToMeta,
  ),
  // INJECT
};
