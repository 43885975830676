import { call, put } from 'redux-saga/effects';

import { handleError } from '@bus/ui/saga/workers/handleError';

import { authActions } from '../../actions';
import { profileActions } from '@bus/profile/actions';

export function* logout({ payload }: any) {
  try {
    yield put(authActions.clearData());
    yield put(profileActions.clearData());
  } catch (e) {
    yield handleError(e);
  } finally {
    yield call(payload, '/welcome');
  }
}
