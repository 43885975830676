import * as effects from 'redux-saga/effects';

import { pagesActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  getSinglePage,
  faq,
} from './workers';

// IMPORTS
function* watchGetSinglePage() {
  yield effects.takeEvery(pagesActions.getSinglePage.type, getSinglePage);
}
function* watchFaq() {
  yield effects.takeEvery(pagesActions.faq.type, faq);
}
// WATCHERS
export function* watchPages() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchGetSinglePage),
    effects.call(watchFaq),
    // INJECT
  ]);
}
