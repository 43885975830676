import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { proposalUsersActions } from '../../actions';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { endpoints } from '@REST/endpoints';
import { ActionsWithProposalActionPayload } from '@bus/proposalUsers/typedefs';

export function* actionsWithProposal({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<ActionsWithProposalActionPayload>) {
  try {
    yield effects.put(proposalUsersActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      {
        endpoint: `${endpoints.proposalUsers}${payload.id}/${payload.action}/`,
        body,
      },
    ]);

    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    yield effects.put(
      proposalUsersActions.removeProposalFromListById(payload.id),
    );
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(proposalUsersActions.stopFetching());
  }
}
