import React from 'react';

import { PaletteMode, useMediaQuery } from '@mui/material';
import { ThemeOptions, createTheme } from '@mui/material/styles';

import { breakpoints } from './breakpoints';
import { MuiButton } from './components/MuiButtonBase';
import { MuiSvgIcon } from './components/MuiSVGIcon';
import { MuiTypography } from './components/MuiTypography';
import { mixins } from './mixins';
import { dark } from './palette/dark';
import { light } from './palette/light';
import { typography } from './typography';
import { MuiOutlinedInput } from './components/MuiOutlinedInput';
import { MuiInputLabel } from './components/MuiInputLabel';
import { MuiFormHelperText } from './components/MuiFormHelperText';
import { MuiPaper } from '@themes/main/components/MuiPaper';
import { MuiSelect } from '@themes/main/components/MuiSelect';
import { MuiList } from '@themes/main/components/MuiList';
import { MuiAccordion } from '@themes/main/components/MuiAccordion';
import { MuiTooltip } from '@themes/main/components/MuiTooltip';

export const themeOptions: Partial<ThemeOptions> = {
  mixins,
  breakpoints,
  palette: light,
  typography,
  components: {
    ...MuiTypography,
    ...MuiButton,
    ...MuiSvgIcon,
    ...MuiOutlinedInput,
    ...MuiInputLabel,
    ...MuiFormHelperText,
    ...MuiPaper,
    ...MuiSelect,
    ...MuiList,
    ...MuiAccordion,
    ...MuiTooltip,
  },
};

export const getDesignTokens = (mode: PaletteMode) => ({
  ...themeOptions,
  palette: {
    mode,
    ...(mode === 'light' ? light : dark),
  },
});
export const useAppTheme = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return React.useMemo(
    () => createTheme(getDesignTokens('light')),
    [prefersDarkMode],
  );
};
