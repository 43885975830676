import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend/cjs';

const lang = localStorage.getItem('language') || 'en';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    lng: lang,
    fallbackLng: lang,
    backend: {
      loadPath: `${process.env.REACT_APP_API_DIRECT_URL}/static/translations/{{lng}}.json`,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  })
  .then(() => {
    localStorage.setItem('language', lang);
  });
