import * as effects from 'redux-saga/effects';

import { optionsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchLanguages,
  fetchSettings,
} from './workers';

// IMPORTS
function* watchGetLanguages() {
  yield effects.takeEvery(optionsActions.getLanguages.type, fetchLanguages);
}
function* watchGetSettings() {
  yield effects.takeEvery(optionsActions.getSettings.type, fetchSettings);
}
// WATCHERS
export function* watchOptions() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchGetLanguages),
    effects.call(watchGetSettings),
    // INJECT
  ]);
}
