/* eslint-disable prettier/prettier */
import React, { lazy } from 'react';

import layouts from '@layouts/index';
import { commonRoutes } from '@pages/common/routes';
import { book } from '@routes/book';
import { AppRoute } from '@setup/typedefs';
const SubscribePage = lazy(()=>import('@pages/private/SubscribePage'));
const ProfilePage = lazy(()=>import('@pages/private/ProfilePage'));
const EditPersonalInformationPage = lazy(()=>import('@pages/private/EditPersonalInformationPage'));
const EditBirthdayPage = lazy(()=>import('@pages/private/EditBirthdayPage'));
const EditResidentialAddressPage = lazy(()=>import('@pages/private/EditResidentialAddressPage'));
const EditCivilStatusPage = lazy(()=>import('@pages/private/EditCivilStatusPage'));
const EditPartnerPage = lazy(()=>import('@pages/private/EditPartnerPage'));
const EditDependableChildrenPage = lazy(()=>import('@pages/private/EditDependableChildrenPage'));
const EditContactInformationPage = lazy(()=>import('@pages/private/EditMailPage'));
const EmailChangePage = lazy(()=>import('@pages/private/EmailChangePage'));
const EditPhonePage = lazy(()=>import('src/pages/private/EditPhonePage'));
const PhoneCodePage = lazy(()=>import('@pages/private/PhoneCodePage'));
const TwoStepVerificationPage = lazy(()=>import('@pages/private/TwoStepVerificationPage'));
const CodeForTwoStepVerificationPage = lazy(()=>import('@pages/private/CodeForTwoStepVerificationPage'));
const CheckYouEmailPage = lazy(()=>import('@pages/common/CheckYouEmailPage'));

// IMPORTS

export const nonSubscriberPrivateRoutes: AppRoute<any>[] = [
  ...[
    {
      path: `${book.profile}`,
      Component: <ProfilePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.twoStepVerification}`,
      Component: <TwoStepVerificationPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.codeForTwoStepVerification}/:phone`,
      Component: <CodeForTwoStepVerificationPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editPersonalInformation}`,
      Component: <EditPersonalInformationPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editBirthday}`,
      Component: <EditBirthdayPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editResidentialAddress}`,
      Component: <EditResidentialAddressPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editCivilStatus}`,
      Component: <EditCivilStatusPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editPartner}`,
      Component: <EditPartnerPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editDependableChildren}`,
      Component: <EditDependableChildrenPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editMail}`,
      Component: <EditContactInformationPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.emailChange}/:activation_token`,
      Component: <EmailChangePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.editPhone}`,
      Component: <EditPhonePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.phoneCode}/:phone`,
      Component: <PhoneCodePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.twoStepVerification}`,
      Component: <TwoStepVerificationPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.codeForTwoStepVerification}/:phone`,
      Component: <CodeForTwoStepVerificationPage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.checkYouEmail}/:registration_token/:email`,
      Component: <CheckYouEmailPage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
    {
      path: `${book.subscribe}`,
      Component: <SubscribePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    // INJECT
  ],
  ...commonRoutes,
];
