import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import { styles } from './styles';
import { isMobileDevice } from '@helpers/functions';

export type AuthLayoutProps = {};

const AuthLayout: React.FC<AuthLayoutProps> = () => {
  const isMobile = isMobileDevice();

  return (
    <Box sx={styles.root(isMobile)}>
      <Suspense fallback={<>...loading</>}>
        <Outlet />
      </Suspense>
    </Box>
  );
};

export default AuthLayout;
