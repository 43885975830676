import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { financialRecordsActions } from '../../actions';
import {
  FetchNetIncomeRecordByIdActionPayload,
  FillNetRecordActionPayload,
} from '../../typedefs';
import { endpoints } from '@REST/endpoints';

export function* fetchNetIncomeRecordById({
  payload,
}: PayloadAction<FetchNetIncomeRecordByIdActionPayload>) {
  const { id } = payload;
  try {
    yield effects.put(financialRecordsActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: `${endpoints.netIncome}${id}/` },
    ]);

    if (!response.ok) {
      yield throwError(response);
    }

    const data: FillNetRecordActionPayload = yield effects.call([
      response,
      'json',
    ]);
    yield effects.put(financialRecordsActions.fillNetRecord(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(financialRecordsActions.stopFetching());
  }
}
