// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  ProposalUsersState,
  FillProposalsActionPayload,
} from './typedefs';
import { emptyPaginationState } from '@setup/typedefs';

const initialState: ProposalUsersState = {
  isFetching: false,
  proposals: emptyPaginationState,
  documentPreview: null,
};

const proposalUsersSlice = createSlice({
  name: 'proposalUsers',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    fillProposals(state, action: PayloadAction<FillProposalsActionPayload>) {
      state.proposals = action.payload;
    },
    pushMoreProposals(
      state,
      action: PayloadAction<FillProposalsActionPayload>,
    ) {
      state.proposals.count = action.payload.count;
      state.proposals.next = action.payload.next;
      state.proposals.results = [
        ...state.proposals.results,
        ...action.payload.results,
      ];
    },
    removeProposalFromListById(state, action: PayloadAction<number>) {
      state.proposals.results = state.proposals.results.filter((item) => {
        return item.id !== action.payload;
      });
    },
    fillDocumentPreview(state, action: PayloadAction<string | null>) {
      state.documentPreview = action.payload;
    },
    // INJECT
  },
});

export default proposalUsersSlice;
