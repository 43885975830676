import { createAction } from '@reduxjs/toolkit';

import optionsSlice from './slice';

export const optionsActions = {
  ...optionsSlice.actions,
  getLanguages: createAction('options/getLanguages'),
  getSettings: createAction('options/fetchSettings'),
  // INJECT
};
