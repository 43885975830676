import * as effects from 'redux-saga/effects';

import { proposalUsersActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  getProposals,
  loadMore,
  actionsWithProposal,
} from './workers';

// IMPORTS
function* watchGetProposals() {
  yield effects.takeEvery(proposalUsersActions.getProposals.type, getProposals);
}
function* watchLoadMore() {
  yield effects.takeEvery(proposalUsersActions.loadMore.type, loadMore);
}
function* watchActionWithProposal() {
  yield effects.takeEvery(
    proposalUsersActions.actionWithProposal.type,
    actionsWithProposal,
  );
}
// WATCHERS
export function* watchProposalUsers() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchGetProposals),
    effects.call(watchLoadMore),
    effects.call(watchActionWithProposal),
    // INJECT
  ]);
}
