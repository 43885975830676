import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { book } from '@routes/book';
import { useSelector } from 'react-redux';
import { getProfile } from '@bus/profile/selectors';

type GreetingUserProps = {};

export const GreetingUser: FC<GreetingUserProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);

  return (
    <Box sx={styles.greetingUser}>
      {profile && (
        <>
          <Box
            sx={styles.userPhotoWrapper}
            onClick={() => navigate(book.profile)}>
            {profile.photo && (
              <Box
                component={'img'}
                src={profile.photo}
                sx={styles.userPhoto}
              />
            )}
          </Box>
          {profile?.first_name && (
            <Typography variant={'h6'}>
              {t('Hi', { name: profile.first_name })}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default GreetingUser;
