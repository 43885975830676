import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

import { profileActions } from '../../actions';
import { TwoStepVerificationActionPayload } from '../../typedefs';
import { endpoints } from '@REST/endpoints';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '@core/FinalForm/getServerErrors';
import { call } from 'redux-saga/effects';

export function* twoStepVerification({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<TwoStepVerificationActionPayload>) {
  try {
    yield effects.put(profileActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      {
        endpoint: endpoints.changePhone,
        body,
        headers: { 'content-type': ' application/json' },
      },
    ]);

    if (!response.ok) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);

      reject(formErrors);
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(profileActions.stopFetching());
  }
}
