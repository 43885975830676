import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

import { authActions } from '../../actions';
import { SetNewPasswordActionPayload } from '../../typedefs';
import { endpoints } from '@REST/endpoints';
import {
  FormErrors,
  getServerFormErrors,
  ServerFormErrors,
} from '@core/FinalForm/getServerErrors';
import { call } from 'redux-saga/effects';

export function* setNewPassword({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<SetNewPasswordActionPayload>) {
  try {
    yield effects.put(authActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      {
        endpoint: endpoints.setNewPassword({
          user_id: payload.user_id,
          reset_token: payload.reset_token,
        }),
        body,
        headers: { 'content-type': ' application/json' },
        unsafe: true,
      },
    ]);

    if (!response.ok) {
      const errors: ServerFormErrors = yield call([response, 'json']);
      const formErrors: FormErrors = yield call(getServerFormErrors, errors);

      reject(formErrors);
      yield throwError(response);
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(authActions.stopFetching());
  }
}
