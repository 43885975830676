import { createAction } from '@reduxjs/toolkit';

import pagesSlice from './slice';

export const pagesActions = {
  ...pagesSlice.actions,
  getSinglePage: createAction('pages/getSinglePage', (payload) => {
    return payload;
  }),
  faq: createAction('pages/faq'),
  // INJECT
};
