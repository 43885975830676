import { Components, Theme } from '@mui/material';

export const MuiFormHelperText: Partial<Components<Theme>> = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontFamily: 'Poppins, sans-serif',
        lineHeight: '100%',
        fontWeight: 400,
        fontSize: 12,
      },
    },
  },
};
