import React, { FC } from 'react';

import { Box } from '@mui/material';

import { styles } from './styles';
import NavigationMenuItem from '@components/common/NavigationMenuItem';
import { book } from '@routes/book';
import { useLocation } from 'react-router-dom';
import PieChart01 from '@components/icons/PieChart01';
import List from '@components/icons/List';
import Folder from '@components/icons/Folder';
import HelpCircle from '@components/icons/HelpCircle';
import { useTranslation } from 'react-i18next';

type NavigationMenuProps = {};

export const NavigationMenu: FC<NavigationMenuProps> = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const navList = [
    {
      ariaLabel: t('Dashboard'),
      icon: <PieChart01 sx={{ fontSize: '18px' }} color={'primary'} />,
      isActive: false,
      text: t('Dashboard'),
      to: book.dashboard,
    },
    {
      ariaLabel: t('Todo'),
      icon: <List sx={{ fontSize: '18px' }} color={'primary'} />,
      isActive: false,
      text: t('Todo'),
      to: book.todo,
    },
    {
      ariaLabel: t('Documents'),
      icon: <Folder sx={{ fontSize: '18px' }} color={'primary'} />,
      isActive: false,
      text: t('Documents'),
      to: book.documents,
    },
    {
      ariaLabel: t('Support'),
      icon: <HelpCircle sx={{ fontSize: '18px' }} color={'primary'} />,
      isActive: false,
      text: t('Support'),
      to: book.support,
    },
  ];

  const isActive = (to: string) => {
    return location.pathname.includes(to);
  };

  return (
    <Box sx={styles.navigationMenu}>
      {navList.map((item) => (
        <NavigationMenuItem
          key={item.to}
          {...item}
          isActive={isActive(item.to)}
        />
      ))}
    </Box>
  );
};

export default NavigationMenu;
