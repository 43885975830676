// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { all, call } from 'redux-saga/effects';

import { watchAuth as authSaga } from '@bus/auth/saga/watchers';
import { watchUi as uiSaga } from '@bus/ui/saga/watchers';
import { watchDocuments as documentsSaga } from '@bus/documents/saga/watchers';
import { watchPages as pagesSaga } from '@bus/pages/saga/watchers';
import { watchProfile } from '@bus/profile/saga/watchers';
import { watchOptions } from '@bus/options/saga/watchers';
import { watchDashboard as dashboardSaga } from '@bus/dashboard/saga/watchers';
import { watchFinancialRecords as financialRecordsSaga } from '@bus/financialRecords/saga/watchers';
import { watchProposalUsers as proposalUsersSaga } from '@bus/proposalUsers/saga/watchers';
import { watchAdminView as adminViewSaga } from '@bus/adminView/saga/watchers';

// IMPORTS

export function* rootSaga() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    call(authSaga),
    call(uiSaga),
    call(documentsSaga),
    call(pagesSaga),
    call(watchProfile),
    call(watchOptions),
    call(dashboardSaga),
    call(financialRecordsSaga),
    call(proposalUsersSaga),
    call(adminViewSaga),
    // INJECT
  ]);
}
