import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { proposalUsersActions } from '../../actions';
import {
  FillProposalsActionPayload,
  LoadMoreActionPayload,
} from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

export function* loadMore({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<LoadMoreActionPayload>) {
  try {
    yield effects.put(proposalUsersActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: payload.url, direct: true },
    ]);

    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    const data: FillProposalsActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(proposalUsersActions.pushMoreProposals(data));
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(proposalUsersActions.stopFetching());
  }
}
