export const styles = {
  root: (isMobile: boolean) => ({
    maxWidth: isMobile ? '100%' : '390px',
    minHeight: '100vh',
    margin: '0 auto',
    padding: '20px 20px 127px',
    background: '#F8F3EE',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      flex: 1,
    },
  }),
};
